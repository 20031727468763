/* eslint-disable no-await-in-loop */
/* eslint-disable no-alert, no-console, linebreak-style, no-useless-escape */
import Linkify from 'linkifyjs/react';
import { toast } from 'react-toastify';
import newApi from '../../common/api/newApi';
import EntreLink from '../components/EntreLink';
import Emoji from './Emoji';

export const parseText = (text, noOfChars) => {
  const textArray = text.split(' ');
  const newArray = [];
  let currCharLength = 0;

  if (text.length < noOfChars) {
    return text;
  }

  textArray.forEach((currWord) => {
    if (currCharLength + currWord.length <= noOfChars) {
      currCharLength += currWord.length;
      newArray.push(currWord);
    }
  });

  return `${newArray.join(' ')}...`;
};
export const truncateText = (str, n) => ((str.length > n) ? `${str.substr(0, n - 1)}...` : str);

export function indexOfNth(str, char, nth, fromIndex = 0) {
  const indexChar = str.indexOf(char, fromIndex);
  if (indexChar === -1) {
    return -1;
  } if (nth === 1) {
    return indexChar;
  }
  return indexOfNth(str, char, nth - 1, indexChar + 1);
}

export function trimText(text, chars = 400, noOfLines = 5) {
  const lastSpace = text.lastIndexOf(' ', chars);
  const noOfLineIndex = indexOfNth(text, '\n', noOfLines);
  const lenIndex = text.length <= chars ? text.length : lastSpace;
  const trimIndex = noOfLineIndex !== -1 ? Math.min(noOfLineIndex, lenIndex) : lenIndex;
  return text.length > trimIndex ? `${text.substr(0, trimIndex)}...` : text.substr(0, trimIndex);
}

export const parseTime = (str) => {
  const postedAt = Date.parse(str);
  const currTime = new Date();

  const timeDiff = new Date(currTime - postedAt);
  const msTimeDiff = timeDiff.getTime();
  let timePosted = '';

  if (msTimeDiff / (1000 * 60) < 1) {
    timePosted = Math.floor(msTimeDiff / 1000);
    timePosted = timePosted < 2 ? 'a few seconds ago' : `${timePosted} seconds ago`;
  } else if (msTimeDiff / (1000 * 60 * 60) < 1) {
    timePosted = Math.floor(msTimeDiff / (1000 * 60));
    timePosted = timePosted < 2 ? `${timePosted} minute ago` : `${timePosted} minutes ago`;
  } else if (msTimeDiff / (1000 * 60 * 60 * 24) < 1) {
    timePosted = Math.floor(msTimeDiff / (1000 * 60 * 60));
    timePosted = timePosted < 2 ? `${timePosted} hour ago` : `${timePosted} hours ago`;
  } else if (msTimeDiff / (1000 * 60 * 60 * 24 * 7) < 1) {
    timePosted = Math.floor(msTimeDiff / (1000 * 60 * 60 * 24));
    timePosted = timePosted < 2 ? `${timePosted} day ago` : `${timePosted} days ago`;
  } else if (msTimeDiff / (1000 * 60 * 60 * 730) < 1) {
    // Approximately 730 hours makes a month
    timePosted = Math.floor(msTimeDiff / (1000 * 60 * 60 * 24 * 7));
    timePosted = timePosted < 2 ? `${timePosted} week ago` : `${timePosted} weeks ago`;
  } else if (msTimeDiff / (1000 * 60 * 60 * 8760) < 1) {
    // 8760 hours makes a calender year
    timePosted = Math.floor(msTimeDiff / (1000 * 60 * 60 * 730));
    timePosted = timePosted < 2 ? `${timePosted} month ago` : `${timePosted} months ago`;
  } else {
    // It is over a year
    timePosted = timePosted < 2 ? `${timePosted} year ago` : `${timePosted} years ago`;
    timePosted = Math.floor(msTimeDiff / (1000 * 60 * 60 * 8760));
  }

  return timePosted;
};

export const cleanString = (str) => str.replace(/\s/g, '-').toLowerCase();

export const getHash = (arr) => {
  const charsum = (s) => {
    let i;
    let sum = 0;
    for (i = 0; i < s.length; i += 1) {
      sum += s.charCodeAt(i) * i;
    }
    return sum;
  };

  let i;
  let sum = 0;
  let product = 1;
  for (i = 0; i < arr.length; i += 1) {
    const cs = charsum(arr[i]);
    if (product % cs > 0) {
      product *= cs;
      sum += 65027 / cs;
    }
  }
  return `${sum}`.slice(0, 16);
};

export const mdLinkCleanup = (str) => {
  const regexMdLinks = /@\[([^\[]+)\]\(([\w\/]*)\)/gm;
  let currPos = 0;
  const arr = [];
  if (str) {
    const matches = [...str.matchAll(regexMdLinks)];
    for (let i = 0; i < matches.length; i += 1) {
      const match = matches[i];
      const matched = match[0];
      const name = match[1];
      arr.push(str.slice(currPos, match.index));
      arr.push(`@${name}`);
      currPos = match.index + matched.length;
    }
    arr.push(str.slice(currPos));
  }
  return arr.join('');
};

export const increaseEmoji = (string) => {
  const regex = /(\u00a9|\u00ae|[\u2000-\u2018]|[\u2020-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gm;
  const match = [...string.matchAll(regex)];
  const arr = [];
  let cur = 0;
  if (string && typeof string === 'string') {
    for (let i = 0; i < match.length; i += 1) {
      // const matched = match[0];
      const idx = match[i].index;
      arr.push(string.slice(cur, idx));
      arr.push(<Emoji>{string.slice(idx, idx + 2)}</Emoji>);
      cur = idx + 2;
    }
  }
  arr.push(string.slice(cur, string.length));
  return arr;
};

export const mdLink = (str) => {
  const regexMdLinks = /@\[([^\[]+)\]\(([\w\/]*)\)/gm;
  let currPos = 0;
  const arr = [];
  if (str && typeof str === 'string') {
    const matches = [...str.matchAll(regexMdLinks)];
    for (let i = 0; i < matches.length; i += 1) {
      const match = matches[i];
      const matched = match[0];
      const url = match[2];
      const name = match[1];
      arr.push(<Linkify key={currPos}>{increaseEmoji(str.slice(currPos, match.index))}</Linkify>);
      arr.push(
        <EntreLink key={name} href="/profile/[id]" as={url}>
          {`@${name} `}
        </EntreLink>,
      );
      currPos = match.index + matched.length;
    }
    arr.push(<Linkify key={currPos}>{increaseEmoji(str.slice(currPos))}</Linkify>);
  }
  return arr;
};

export const getUniqueListBy = (arr, key) => [...new Map(arr
  .map((item) => [item[key], item])).values()];

export const getInitials = (nameString) => {
  if (!nameString) return '';
  const fullName = nameString?.split(' ');
  if (fullName && fullName.length > 1) {
    const initials = fullName?.shift().charAt(0) + fullName?.pop()?.charAt(0);
    return initials.toUpperCase();
  }
  return nameString.charAt(0).toUpperCase();
};
// export const getUserPlace = (data) => {
//   let locResult;
//   if (data && data.terms) {
//     const [
// { value: city = '' } = {}, { value: state = '' } = {},
// { value: country = '' } = {}] = data.terms;
//     locResult = { city, state, country };
//     // dispatch(updateLocation(locResult));
//   }
//   return locResult;
// };

export const checkTimeDifference = (date) => {
  const diff = Math.abs(new Date(date) - new Date());
  return Math.floor(diff / 1000 / 60);
};

export const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.substr(1);

export const shortenFollowers = (num, digits = 1) => {
  // the value of the digits is the number of decimal places required.

  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const regex = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const result = lookup.slice().reverse().find((item) => num >= item.value);
  let transformedResult = (num / result?.value).toFixed(digits).replace(regex, '$1');
  if (transformedResult.length > 4) {
    console.log(transformedResult);
    transformedResult = transformedResult.slice(0, -2);
  }
  return result ? transformedResult + result.symbol : '0';
};

export const openNewWindow = (url) => {
  const h = window.outerHeight * 0.9;
  const w = window.outerWidth * 0.8;
  const y = window.outerHeight / 2 + window.screenY - h / 2;
  const x = window.outerWidth / 2 + window.screenX - w / 2;
  window.open(url, null, `toolbar=no, width=${w}, height=${h}, top=${y}, left=${x}`);
};

export const fileUpload = async (file) => {
  try {
    const resp = await newApi.post('upload/pdf/getUploadUrl');
    const newFile = new File(file, resp.data.fileName);
    if (resp?.data?.uploadUrl) {
      const res1 = await newApi.axiosOther.put(resp.data.uploadUrl, newFile, {
        headers: {
          'Content-Type': newFile.type,
        },
      });
      console.log(res1);
      if (res1.status === 200) {
        return resp.data.pdfs.storj;
      }
    }
    toast.warning('Please try again');
    return null;
  } catch (error) {
    console.error(error);
    return toast.error('Something went wrong uploading file');
  }
};

export const imageUpload = async (fileArray) => {
  try {
    const file = fileArray[0];
    const resp = await newApi.post('upload/image/getUploadUrl');
    if (resp?.data?.uploadUrl) {
      const res1 = await newApi.axiosOther.put(resp.data.uploadUrl, file, {
        headers: {
          'Content-Type': file.type,
        },
      });
      if (res1.status === 200) {
        return resp.data.images;
      }
    }
    toast.warning('Please try again');
    return null;
  } catch (error) {
    console.error(error);
    return toast.error('Something went wrong uploading file');
  }
};

export const videoUpload = async (fileArray) => {
  try {
    const file = fileArray[0];
    const resp = await newApi.post('upload/video/getUploadUrl');
    if (resp?.data?.uploadUrl) {
      const res1 = await newApi.axiosOther.put(resp.data.uploadUrl, file, {
        headers: {
          'Content-Type': file.type,
        },
      });
      if (res1.status === 200) {
        return resp.data.videos;
      }
    }
    toast.warning('Please try again');
    return null;
  } catch (error) {
    console.error(error);
    return toast.error('Something went wrong uploading file');
  }
};

export const videoProcessing = async (videoLink) => {
  let processed = false;
  while (!processed) {
    try {
      await new Promise((res) => setTimeout(res, 6000));
      const respProcessed = await newApi.axiosOther.get(videoLink);
      if (respProcessed.status === 200) {
        processed = true;
        return true;
      }
    } catch (error) {
      console.warn('Link is not available');
    }
  }
  return false;
};

// used to sort lists in forms
export const sortOptions = (list) => list.sort((a, b) => a.value.localeCompare(b.value));
