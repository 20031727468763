import { createAction } from 'redux-actions';
import { getStore } from '../../../store';

export const updateEthState = createAction('UPDATE_ETH_STATE');

export const updateUserBalance = createAction('UPDATE_USER_BALANCE', async (bal) => {
  getStore().dispatch(updateEthState({ userEthbalance: bal }));
});

export const updateETHprice = createAction('UPDATE_ETH_PRICE', async (eth) => {
  getStore().dispatch(updateEthState({ price: eth[0].price }));
});
