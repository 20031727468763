import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const VideoContext = createContext(null);

export const VideoContextProvider = ({ children }) => {
  const [curPlayingVidId, setCurPlayingVidId] = useState('');
  const [muted, setMuted] = useState(true);
  const [volume, setVolume] = useState(1.0);

  const pauseVideo = async (postId) => {
    const video = document.getElementById(postId)?.querySelector('video');

    setCurPlayingVidId((prevState) => {
      if (prevState === postId) return '';
      return prevState;
    });

    if (video) {
      video.pause();
    }
  };

  const playVideo = async (postId) => {
    if (curPlayingVidId && curPlayingVidId !== postId) {
      pauseVideo(curPlayingVidId);
    }

    const video = document.getElementById(postId)?.querySelector('video');
    if (video) {
      video.volume = volume;
      if (video.duration <= 30) {
        video.loop = true;
      }
      video.play();
      setCurPlayingVidId(postId);
    }
  };

  const setIsMuted = (val) => setMuted(val);

  const setGlobalVolume = (val) => setVolume(val);

  const values = {
    curPlayingVidId,
    playVideo,
    pauseVideo,
    muted,
    setIsMuted,
    setGlobalVolume,
    volume,
  };
  return (
    <VideoContext.Provider value={values}>
      {children}
    </VideoContext.Provider>
  );
};

VideoContextProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export default VideoContext;
