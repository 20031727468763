export const AUTH = {
  INIT_LOGIN: 'INIT_LOGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
  LOGIN_FAIL: 'LOGIN_FAIL',
  SIGNUP_FAIL: 'SIGNUP_FAIL',
  LOGOUT: 'LOGOUT',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  SET_IDTOKEN_SUCCESS: 'SET_IDTOKEN_SUCCESS',
  SET_IDTOKEN_FAIL: 'SET_IDTOKEN_FAIL',
  CLEAR_ERROR: 'CLEAR_ERROR',
  SKIP_ONBOARDING_FLOW: 'SKIP_ONBOARDING_FLOW',
  OPEN_PROMODAL_AFTER_LOGIN: 'OPEN_PROMODAL_AFTER_LOGIN',
};

export const ACCOUNT = {
  INIT_ACCOUNT: 'INIT_ACCOUNT',
  LOAD_ACCOUNT_SUCCESS: 'LOAD_ACCOUNT_SUCCESS',
  LOAD_ACCOUNT_FAIL: 'LOAD_ACCOUNT_FAIL',
  UPDATE_ACCOUNT: 'UPDATE_ACCOUNT',
  UPDATE_ACCOUNT_SUCCESS: 'UPDATE_ACCOUNT_SUCCESS',
  UPDATE_ACCOUNT_FAIL: 'UPDATE_ACCOUNT_FAIL',
  LOAD_INDUSTRIES: 'LOAD_INDUSTRIES',
  LOAD_TITLES: 'LOAD_TITLES',
  LOAD_INDUSTRIES_SUCCESS: 'LOAD_INDUSTRIES_SUCCESS',
  LOAD_TITLES_SUCCESS: 'LOAD_TITLES_SUCCESS',
  LOAD_CONNECTIONS: 'LOAD_CONNECTIONS',
  LOAD_CONNECTIONS_SUCCESS: 'LOAD_CONNECTIONS_SUCCESS',
  LOAD_ACCOUNT_POST: 'LOAD_ACCOUNT_POST',
  LOAD_ACCOUNT_POST_SUCCESS: 'LOAD_ACCOUNT_POST_SUCCESS',
  UPDATE_FOLLOWINGS: 'UPDATE_FOLLOWINGS',
};

export const ERRORS = {
  LOG_ERROR: 'LOG_ERROR',
  CLEAR_ERROR: 'CLEAR_ERROR',
  CLEAR_ALL_ERRORS: 'CLEAR_ALL_ERRORS',
};

export const ONBOARDING = {
  UPDATE_STEP: 'UPDATE_STEP',
  UPDATE_TITLE: 'UPDATE_TITLE',
  UPDATE_COMPANY: 'UPDATE_COMPANY',
  UPDATE_USERNAME: 'UPDATE_USERNAME',
  UPDATE_EMAIL: 'UPDATE_EMAIL',
  UPDATE_AUTH_EMAIL: 'UPDATE_AUTH_EMAIL',
  UPDATE_FULL_NAME: 'UPDATE_FULL_NAME',
  UPDATE_TITLES: 'UPDATE_TITLES',
  UPDATE_INTERESTS: 'UPDATE_INTERESTS',
  UPDATE_INDUSTRIES: 'UPDATE_INDUSTRIES',
  UPDATE_PROFILE: 'UPDATE_PROFILE',
  UPDATE_LOCATION: 'UPDATE_LOCATION',
  SUBMIT: 'SUBMIT',
  COMPLETED: 'COMPLETED',
  FETCHING_LOCATION: 'FETCHING_LOCATION',
  FETCHING_LOCATION_COMPLETE: 'FETCHING_LOCATION_COMPLETE',
};

export const MAIN = {
  DISMISS_VIDEO_MODAL: 'DISMISS_VIDEO_MODAL',
  DISMISS_APP_MODAL: 'DISMISS_APP_MODAL',
  SWITCH_PALLETE_COLOR: 'SWITCH_PALLETE_COLOR',
  REDIRECT_AFTER_ONBOARDING: 'REDIRECT_AFTER_ONBOARDING',
  RESET_DISMISS_APP_MODAL: 'RESET_DISMISS_APP_MODAL',
  RESET_REDIRECT: 'RESET_REDIRECT',
  PHONE_VERIFIED_SUCCESS: 'PHONE_VERIFIED_SUCCESS',
  RESET_REDIRECT_TO_BLOG: 'RESET_REDIRECT_TO_BLOG',
  REDIRECT_TO_BLOG: 'REDIRECT_TO_BLOG',
};

export const CHATS = {
  INIT_CHATS: 'INIT_CHATS',
  LOAD_CHATS_SUCCESS: 'LOAD_CHATS_SUCCESS',
};

export const CHAT = {
  INIT_CHAT: 'INIT_CHAT',
  INIT_LOAD_CHAT: 'INIT_LOAD_CHAT',
  LOAD_CHAT_SUCCESS: 'LOAD_CHAT_SUCCESS',
  LOAD_CHAT_USERS_SUCCESS: 'LOAD_CHAT_USERS_SUCCESS',
  LOAD_CHAT_USERS_FAIL: 'LOAD_CHAT_USERS_FAIL',
  SET_ACTIVE_CHAT_ID: 'SET_ACTIVE_CHAT_ID',
};
export const NOTIFICATIONS = {
  INIT_NOTIFICATIONS: 'INIT_NOTIFICATIONS',

};
