import { MAIN } from '../actions/types';

const INITIAL_STATE = {
  videoModalDismissed: false,
  appModalDismissed: undefined,
  darkMode: true,
  lightMode: false,
  redirect: null,
  redirectBlog: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MAIN.DISMISS_APP_MODAL:
      return {
        ...state,
        appModalDismissed: true,
      };
    case MAIN.DISMISS_VIDEO_MODAL:
      return {
        ...state,
        videoModalDismissed: true,
      };
    case MAIN.RESET_DISMISS_APP_MODAL:
      return {
        ...state,
        appModalDismissed: false,
      };
    case MAIN.REDIRECT_AFTER_ONBOARDING:
      return {
        ...state,
        redirect: action.payload,
      };
    case MAIN.RESET_REDIRECT:
      return {
        ...state,
        redirect: null,
      };
    case MAIN.REDIRECT_TO_BLOG:
      return {
        ...state,
        redirectBlog: true,
      };
    case MAIN.RESET_REDIRECT_TO_BLOG:
      return {
        ...state,
        redirectBlog: null,
      };
    case MAIN.SWITCH_PALLETE_COLOR: {
      const { darkMode } = state;
      const actualDarkMode = darkMode;
      return {
        ...state,
        darkMode: !actualDarkMode,
        lightMode: darkMode,
      };
    }
    default:
      return state;
  }
};
