import { createAction } from 'redux-actions';
import { getStore } from '../../../store';
import newApi from '../../api/newApi';

export const setPitchFilter = createAction('SET_FEED_FILTER');
export const loadPitchesComplete = createAction('LOAD_PITCHES_SUCCESS');
export const setCurrentPitchPage = createAction('SET_FILTER_PAGE');
export const setCurrentPitchSearch = createAction('SET_SEARCH_QUERY_HOME');
export const resetPitches = createAction('RESET_PITCHES', () => {
  newApi.cancelAll();
});
export const loadPitches = createAction('LOAD_PITCHES', async (limit = 10, offset = 0, filter = null) => {
  console.log(filter);
  const { auth } = getStore().getState();
  let url = `pitch?limit=${limit}&offset=${offset}`;
  if (!auth.token) {
    url = `public/${url}`;
  }
  if (filter) {
    if (filter.industry && filter.industry !== 'All') {
      url += `&industry=${filter.industry}`;
    }
    if (filter.location && filter.location !== 'All') {
      url += `&city=${filter.location.city}`;
    }
    if (filter.feedFilter && filter.feedFilter === 'following') {
      url += '&connectedUsers=true';
    } else if (filter.feedFilter) {
      url += `&sortBy=${filter.feedFilter}`;
    }
    if (filter.searchQuery && filter.searchQuery !== '') {
      url += `&searchText=${filter.searchQuery}`;
    }
  }
  const resp = await newApi.get(url, 'feed', true);
  if (resp && resp.data) {
    getStore().dispatch(loadPitchesComplete(resp.data));
  } else {
    // dispatch(loadFeedFailed(resp.data.error));
  }
});

export const deletePitchComplete = createAction('DELETE_POST_SUCCESS');
export const deletePitch = createAction('DELETE_POST', async (postId) => {
  const resp = await newApi.delete(`pitch/${postId}`);
  if (resp) {
    getStore().dispatch(deletePitchComplete(postId));
  }
  return resp;
});
