import { AUTH } from '../actions/types';

const INITIAL_STATE = {
  userAuth: {},
  token: undefined,
  error: undefined,
  loaded: false,
  updateToPro: false,
  skipOnboardingFlow: false,
};

// const isRoomSignUp = (url) => {
//   const content = url.split('?');
//   const item = content.filter((el) => el.startsWith('roomSignUp')).toString();
//   const requiredValue = item.split('=')[1];
//   return requiredValue === 'true';
// };
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTH.INIT_LOGIN:
      return INITIAL_STATE;
    case AUTH.LOGIN_SUCCESS:
      return {
        ...state,
        loaded: true,
        userAuth: action.payload,
      };
    case AUTH.OPEN_PROMODAL_AFTER_LOGIN:
      return {
        ...state,
        updateToPro: !state.updateToPro,
      };

    case AUTH.SIGNUP_SUCCESS:
      return {
        ...state,
        error: undefined,
        userAuth: action.payload,
      };
    case AUTH.LOGIN_FAIL:
    case AUTH.SIGNUP_FAIL:
      return {
        ...INITIAL_STATE,
        error: action.payload,
        loaded: true,
      };
    case AUTH.CLEAR_ERROR:
      return {
        ...state,
        error: undefined,
      };
    case AUTH.SET_IDTOKEN_SUCCESS:
      return {
        ...state,
        token: action.payload,
      };
    case AUTH.SET_IDTOKEN_FAIL:
      return INITIAL_STATE;
    case AUTH.LOGOUT:
      return {
        ...INITIAL_STATE,
      };
    case AUTH.LOGOUT_SUCCESS:
      return {
        ...INITIAL_STATE,
        loaded: true,
      };
    case AUTH.SKIP_ONBOARDING_FLOW:
      return {
        ...state,
        skipOnboardingFlow: false,
      };
    default:
      return state;
  }
};
