/* eslint-disable no-console */
import {
  ConsoleLogger,
  DefaultDeviceController,
  DefaultMeetingSession,
  LogLevel,
  MeetingSessionConfiguration,
} from 'amazon-chime-sdk-js';
import newApi from '../../common/api/newApi';

let CHIME_LOGGER;
let CHIME_DEVICE_CONTROLLER;
if (process.browser) {
  try {
    CHIME_LOGGER = new ConsoleLogger('Chime Logs', LogLevel.OFF);
    CHIME_DEVICE_CONTROLLER = new DefaultDeviceController(CHIME_LOGGER);
  } catch (err) {
    console.log(err.message);
  }
}

export async function getVideoDevices() {
  try {
    const devices = await CHIME_DEVICE_CONTROLLER.listVideoInputDevices();
    return devices;
  } catch (e) {
    console.log('Permission denied', e);
    return { err: e };
  }
}

export async function startVideoPreview(videoElement, device) {
  if (videoElement) {
    const devices = await CHIME_DEVICE_CONTROLLER.listVideoInputDevices();
    try {
      console.log(device);
      await CHIME_DEVICE_CONTROLLER.chooseVideoInputDevice(null);
      await CHIME_DEVICE_CONTROLLER.chooseVideoInputDevice(device || devices[0]);
      console.log('Registering preview video input device:', devices[0].label);
      await CHIME_DEVICE_CONTROLLER.startVideoPreviewForVideoInput(videoElement);
    } catch (e) {
      console.log('Permission denied', e);
      return { err: e };
    }
    if (devices.length === 0) {
      return { err: 'No video input devices detected.' };
    }
  } else {
    await CHIME_DEVICE_CONTROLLER.chooseVideoInputDevice(null);
  }
  return { result: 'Video preview input registered.' };
}

export async function getAudioDevices() {
  try {
    const devices = await CHIME_DEVICE_CONTROLLER.listAudioInputDevices();
    return devices;
  } catch (e) {
    console.log('Permission denied', e);
    return { err: e };
  }
}

export async function startAudioPreview(audioElement, device) {
  const devices = await CHIME_DEVICE_CONTROLLER.listAudioInputDevices();
  if (audioElement) {
    try {
      if (device) {
        await CHIME_DEVICE_CONTROLLER.chooseAudioInputDevice(null);
      }
      await CHIME_DEVICE_CONTROLLER.chooseAudioInputDevice(device || devices[0]);
    } catch (e) {
      console.log('Permission denied', e);
      return { err: e };
    }
  } else {
    await CHIME_DEVICE_CONTROLLER.chooseAudioInputDevice(null);
  }
  return { result: 'Audio preview input registered.' };
}

export async function previewVolumeIndicator() {
  const analyserNode = CHIME_DEVICE_CONTROLLER.createAnalyserNodeForAudioInput();
  return analyserNode;
}

export async function connectToSession(meeting, ticket) {
  const meetingConfiguration = new MeetingSessionConfiguration(meeting, ticket);
  // meetingConfiguration.attendeePresenceTimeoutMs = 15000;

  try {
    const meetingSession = new DefaultMeetingSession(
      meetingConfiguration,
      CHIME_LOGGER,
      CHIME_DEVICE_CONTROLLER,
    );
    meetingSession.audioVideo.setDeviceLabelTrigger(
      async () => {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: true,
        });
        return stream;
      },
    );
    return meetingSession;
  } catch (err) {
    return null;
  }
}

export async function getParticipantJoinTicket(roomId) {
  const apiRes = await newApi.post('connect/sessions/join', { roomId });
  if (apiRes && apiRes.data) {
    if (apiRes.data.error) {
      if (apiRes.data.debug) {
        const rawError = apiRes.data.debug;
        if (rawError.includes('maximum number of attendees')) {
          throw new Error('Exceeded maximum number of attendees, please try again later');
        }
      }
      throw new Error(apiRes.data.error);
    }
    return apiRes.data;
  }
  return null;
}

export async function startScreenRecording(roomId, action) {
  const apiRes = await newApi.post('room/record', { roomId, action });
  return apiRes;
}

/* function getSupportedMediaRegions() {
  const supportedMediaRegions = [
    'us-east-1',
    'ap-northeast-1',
    'ap-southeast-1',
    'af-south-1',
    'eu-south-1',
    'ap-south-1',
    'ap-northeast-2',
    'ap-southeast-2',
    'ca-central-1',
    'eu-central-1',
    'eu-north-1',
    'eu-west-1',
    'eu-west-2',
    'eu-west-3',
    'sa-east-1',
    'us-east-2',
    'us-west-1',
    'us-west-2',
  ];
  return supportedMediaRegions;
}

async function getNearestMediaRegion() {
  const nearestMediaRegionResponse = await fetch('https://nearest-media-region.l.chime.aws', {
    method: 'GET',
  });
  const nearestMediaRegionJSON = await nearestMediaRegionResponse.json();
  const nearestMediaRegion = nearestMediaRegionJSON.region;
  return nearestMediaRegion;
} */
