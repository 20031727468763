/* eslint-disable consistent-return, jsx-a11y/media-has-caption */

import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

function AudioSpeaker() {
  const audioElementRef = useRef(null);
  const session = useSelector((state) => state.rooms.session);
  const isJoining = useSelector((state) => state.rooms.isJoining);

  useEffect(() => {
    // eslint-disable-next-line no-console
    if (isJoining || !session || session.error || !process.browser) return;
    const bindAudio = async () => {
      const audioElement = audioElementRef.current;
      try {
        // eslint-disable-next-line no-console
        console.debug('Attaching audio playback control.', audioElement);
        await session.audioVideo.bindAudioElement(audioElement);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.debug('failed to bindAudioElement', err.message);
      }
    };
    bindAudio();
    const observer = {
      audioOutputsChanged: async (outputDevices) => {
        // eslint-disable-next-line no-console
        console.debug('Audio outputs updated: ', outputDevices);
        try {
          await session.audioVideo.chooseAudioOutputDevice('default');
          bindAudio();
        } catch (e) {
          // eslint-disable-next-line no-console
          console.debug('failed to chooseAudioOutputDevice', e);
        }
      },
    };
    session.audioVideo.addDeviceChangeObserver(observer);
    return () => {
      session.audioVideo.unbindAudioElement();
      session.audioVideo.removeDeviceChangeObserver(observer);
    };
  }, [session, isJoining]);

  if (!session || isJoining) return null;
  return <audio ref={audioElementRef} />;
}

export default AudioSpeaker;
