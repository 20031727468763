import Pubnub from 'pubnub';

class PubnubInstance {
  constructor() {
    this.pubnub = null;
    this.user = null;
  }

  setUser(user) {
    this.user = user;
  }

  initPubnubInstance() {
    if (!this.pubnub) {
      const pubnubListeners = {
        message: this.handleMessageEvent.bind(this),
        status: (event) => console.log(event),
      };

      const pubnubConfig = {
        publishKey: process.env.PUBNUB_PUBLISH_KEY_CHAT,
        subscribeKey: process.env.PUBNUB_SUBSCRIBE_KEY_CHAT,
        uuid: this.user.id,
        // logVerbosity: true,
      };
      this.pubnub = new Pubnub(pubnubConfig);
      this.pubnub.addListener(pubnubListeners);
    }
  }

  async subscribeToChannels(ids = [], isChannelGroup = false) {
    if (!this.pubnub) return;
    if (isChannelGroup) {
      this.pubnub.subscribe(
        { channelGroups: ids },
      );
    } else {
      this.pubnub.subscribe(
        { channels: ids },
      );
    }
  }

  async unsubscribeFromChannels(ids = [], isChannelGroup = false) {
    if (!this.pubnub) return;
    if (isChannelGroup) {
      this.pubnub.unsubscribe(
        { channelGroups: ids },
      );
    } else {
      this.pubnub.unsubscribe(
        { channels: ids },
      );
    }
  }

  async fetchMessages(channels = [], msgCount, startTToken = '') {
    let messagesRes;

    try {
      if (startTToken) {
        messagesRes = await this.pubnub.fetchMessages({
          channels,
          count: msgCount,
          start: startTToken,
        });
      } else {
        messagesRes = await this.pubnub.fetchMessages({
          channels,
          count: msgCount,
        });
      }
    } catch (err) {
      return undefined;
    }

    return messagesRes.channels;
  }

  // eslint-disable-next-line class-methods-use-this
  async publish(msgObject, channel) {
    try {
      await this.pubnub.publish({
        message: msgObject,
        channel,
      });
      return true;
    } catch (error) {
      return false;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async handleMessageEvent(event) {
    if (event.channel.startsWith('ch_')) {
      window.dispatchEvent(new CustomEvent('chatStoreAddMessageEvent', { detail: { msgEvent: event } }));
    } else if (event.channel.startsWith('room_')) {
      window.dispatchEvent(new CustomEvent('roomStoreAddMessageEvent', { detail: { msgEvent: event } }));
    }
  }
}

export default new PubnubInstance();
