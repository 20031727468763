import { createAction } from 'redux-actions';
import { getStore } from '../../../store';
import newApi from '../../api/newApi';
import { ACCOUNT } from './types';

export const setPeopleFilter = createAction('SET_PEOPLE_FILTER');
export const loadPeopleComplete = createAction('LOAD_PEOPLE_SUCCESS');
export const resetPeople = createAction('RESET_PEOPLE');
export const loadPeople = createAction('LOAD_PEOPLE', async (limit = 10, offset = 0, filter = null) => {
  let url = `user?limit=${limit}&offset=${offset}`;
  console.log('How filter is set', filter);
  if (filter) {
    if (filter.industry && filter.industry !== 'All') {
      url += `&industry=${filter.industry}`;
    }
    if (filter.title && filter.title !== 'All') {
      url += `&title=${filter.title}`;
    }
    if (filter.location && filter.location !== '') {
      url += `&city=${filter.location.city}`;
    }
    // if (filter.searchQuery && filter.searchQuery !== '') {
    //   url += `&searchText=${filter.searchQuery}`;
    // }
    if (filter.peopleFilter && filter.peopleFilter !== 'popular') {
      url += `&sort=${filter.peopleFilter}`;
    }
  }
  const resp = await newApi.get(url, 'users', true);

  if (resp && resp.data) {
    getStore().dispatch(loadPeopleComplete(resp.data));
  } else {
    getStore().dispatch(loadPeopleComplete([]));
  }
});

export const updateUserfollowingsCount = (action) => async (dispatch) => {
  // action will receive if we should increase or decrease counter (1 or -1)
  dispatch({
    type: ACCOUNT.UPDATE_FOLLOWINGS,
    payload: action,
  });
};

export const updateUserConnectionComplete = createAction('UPDATE_CONNECTION_SUCCESS');
export const updateUserConnection = createAction('UPDATE_CONNECTION', async (userId, mode, actionUser) => {
  let url = '';
  let sucessStatus = '';
  let failStatus = '';
  let postBody = {};
  let action = null;
  switch (mode) {
    case 'Connected':
      url = 'user/follower/unfollow';
      sucessStatus = 'Follow back';
      failStatus = 'Connected';
      action = -1;
      postBody = {
        connectedUserId: userId,
      };
      window.analytics.track('Connection Unfollow', { id: userId });
      break;
    case 'Following':
      url = 'user/follower/unfollow';
      sucessStatus = 'Follow';
      failStatus = 'Following';
      action = -1;
      postBody = {
        connectedUserId: userId,
      };
      window.analytics.track('Connection Unfollow', { id: userId });
      break;
    case 'Follow back':
      url = 'user/follower/request';
      sucessStatus = 'Following';
      failStatus = 'Follow back';
      action = +1;
      postBody = {
        connectedUserId: userId,
      };
      window.analytics.track('Connection follow', { id: userId });
      break;
    case 'follow back':
      url = 'user/follower/request';
      sucessStatus = 'Following';
      failStatus = 'Follow back';
      action = +1;
      postBody = {
        connectedUserId: userId,
      };
      window.analytics.track('Connection follow', { id: userId });
      break;
    case 'Follow':
      url = 'user/follower/request';
      sucessStatus = 'Following';
      failStatus = 'Follow';
      action = +1;
      postBody = {
        connectedUserId: userId,
      };
      window.analytics.track('Connection Request', { id: userId });
      break;
    default:
      break;
  }
  if (url !== '') {
    try {
      const resp = await newApi.post(url, postBody);
      if (actionUser) {
        getStore().dispatch(updateUserfollowingsCount(action));
      }
      return { message: resp.data.data, sucessStatus, failStatus };
    } catch (err) {
      return { error: err };
    }
  }
  return { message: 'Request already sent' };
});

export const loadBlockedUsersComplete = createAction('LOAD_BLOCKED_USERS_SUCCESS');
export const loadBlockedUsers = createAction('LOAD_BLOCKED_USERS', async () => {
  const url = 'user/blocked';
  const resp = await newApi.get(url);
  if (resp && resp.data.data) {
    getStore().dispatch(loadBlockedUsersComplete(resp.data.data));
  } else {
    // dispatch(loadBlockedUsersFailed(resp.data.error));
  }
});

export const unblockUserComplete = createAction('UNBLOCK_USER_SUCCESS');
export const unblockUser = createAction('UNBLOCK_USER', async (unBlockUserId) => {
  const postBody = {
    unBlockUserId,
  };
  window.analytics.track('User Unblock', { id: unBlockUserId });
  const resp = await newApi.post('user/unblock', postBody);
  if (resp && resp.data.data) {
    getStore().dispatch(unblockUserComplete(unBlockUserId));
    return { message: resp.data.data };
  }
  // dispatch(loadPeopleFailed(resp.data.error));
  return { message: resp.data.data };
});

export const upgradeUserToProComplete = createAction('UPGRADE_USER_SUCCESS');
export const upgradeUserToPro = createAction('UPGRADE_USER', async (userIds, isPro = true) => {
  const postBody = {
    userIds,
    isPro,
  };
  const resp = await newApi.post('user/upgradetopro', postBody);
  if (resp && resp.data.data) {
    getStore().dispatch(upgradeUserToProComplete({ userIds, isPro }));
    return { message: resp.data.data };
  }
  // dispatch(loadPeopleFailed(resp.data.error));
  return { message: resp.data.data };
});

export const updateUserRoomCreationPermissionComplete = createAction('UPDATE_ROOM_PERMISSIONS_COMPLETE');
export const updateRoomPermissions = createAction('UPDATE_ROOM_PERMISSIONS', async (userIds, canCreateRooms) => {
  const postBody = {
    userIds,
    canCreateRooms,
  };
  const resp = await newApi.post('user/update-room-permissions', postBody);
  if (resp && resp.data) {
    getStore().dispatch(updateUserRoomCreationPermissionComplete({ userIds, canCreateRooms }));
    return { message: resp.data };
  }
  return { message: resp.data };
});

export const deleteUserComplete = createAction('DELETE_USER_SUCCESS');
export const deleteUser = createAction('DELETE_USER', async (userId) => {
  const resp = await newApi.delete(`user/${userId}`);
  if (resp && resp.data) {
    getStore().dispatch(deleteUserComplete(userId));
  }
  // dispatch(loadPeopleFailed(resp.data.error));
  return { message: resp.data };
});
