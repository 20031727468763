/* eslint-disable no-unused-vars */
/* eslint-disable no-alert, no-console, linebreak-style, no-use-before-define */
import React, {
  createContext, useEffect, useState, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Subject } from 'rxjs';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import desoApi from '../../common/api/deso';
import newApi from '../../common/api/newApi';
import { defaultProfileImage } from '../../common/data/otherConstant';
import {
  desoHasPhoneVerified, desoProfileComplete, loadAccount, loadGlobalFeed, loadProfileData,
} from '../../common/data/actions';

export const DesoContext = createContext();

const DesoContextProvider = ({ children }) => {
  const dispatch = useDispatch();
  const loaded = useSelector((state) => state.auth.loaded);
  const [inf, setInfo] = useState({ browserSupported: true });
  const pendingRequests = useRef([]);
  const initialized = useRef(false);
  const [credentials, setCredentials] = useState(null);
  const [desoUsername, setDesoUsername] = useState(null);
  const [isDesoUser, setIsDesoUser] = useState(false);
  const iframe = useRef();
  const identityWindow = useRef();
  const identityWindowSubject = useRef();
  const identityServiceURL = process.env.DESO_IDENTITY_URL;
  const user = useSelector((state) => state.account.user) || {};
  const outboundRequests = useRef({});
  const storageGranted = new Subject();
  // Incoming messages

  const postMessage = (req) => {
    if (initialized.current) {
      if (iframe?.current?.contentWindow) {
        iframe.current.contentWindow.postMessage(req, '*');
      }
    } else {
      pendingRequests.current.push(req);
    }
  };

  // Respond to a received message
  const respond = (window, id, payload) => {
    window?.postMessage({ id, service: 'identity', payload }, '*');
  };

  const handleInitialize = (event) => {
    if (!initialized.current) {
      initialized.current = true;
      iframe.current = document.getElementById('identity');
      for (let i = 0; i < pendingRequests.current.length; i += 1) {
        const request = pendingRequests.current[i];
        postMessage(request);
      }
      pendingRequests.current = [];
    }

    // acknowledge, provides hostname data
    respond(event.source, event.data.id, {});
  };

  const handleStorageGranted = () => {
    storageGranted.next(true);
    storageGranted.complete();
  };

  const handleLogin = (payload) => {
    if (identityWindow.current) {
      identityWindow.current.close();
    }
    identityWindow.current = null;
    console.log(payload);
    if (payload.publicKeyAdded) {
      setCredentials(payload.users[payload.publicKeyAdded]);
      localStorage.setItem('identityUsers', JSON.stringify(payload.users[payload.publicKeyAdded]));
    }
    identityWindowSubject.current.next(payload);
    identityWindowSubject.current.complete();
    identityWindowSubject.current = null;
  };

  const handleImport = (id) => {
    respond(identityWindow.current, id, { identities: [] });
  };

  const handleInfo = (id) => {
    respond(identityWindow.current, id, {});
  };

  const handleRequest = (event) => {
    const {
      data: { id, method, payload },
    } = event;

    if (method === 'initialize') {
      handleInitialize(event);
    } else if (method === 'storageGranted') {
      handleStorageGranted();
    } else if (method === 'login') {
      handleLogin(payload);
    } else if (method === 'import') {
      handleImport(id);
    } else if (method === 'info') {
      handleInfo(id);
    } else {
      console.error('Unhandled identity request');
    }
  };

  const handleResponse = (event) => {
    const {
      data: { id, payload },
    } = event;

    const req = outboundRequests.current[id];
    req.next(payload);
    req.complete();
    delete outboundRequests.current[id];
  };

  const handleMessage = (event) => {
    const { data } = event;
    const { service, method } = data;

    if (service !== 'identity') {
      return;
    }

    // Methods are present on incoming requests but not responses
    if (method) {
      handleRequest(event);
    } else {
      handleResponse(event);
    }
  };

  const checkDesoUsernameAlreadyTaken = async (username) => {
    try {
      const res = await desoApi.post('get-single-profile', {
        Username: username,
        NoErrorOnMissing: true,
        PublicKeyBase58Check: '',
      });
      if (!res || res.IsBlackListed) {
        // toast.error('Error fetching user deso profile');
      }
      if (res.data.Profile) {
        setDesoUsername(username.concat('_'));
      }
    } catch (error) {
      console.log(error);
      // toast.error('Error fetching user deso profile');
    }
  };

  useEffect(() => {
    if (user.deso) {
      setIsDesoUser(true);
    }
    if (user?.username) {
      setDesoUsername(user.username);
      checkDesoUsernameAlreadyTaken(user.username);
    }
    dispatch(loadProfileData());
    return () => {};
  }, [user]);

  const getIsFollowing = async (pk) => {
    try {
      const res = await desoApi.post('is-following-public-key', {
        PublicKeyBase58Check: user.deso,
        IsFollowingPublicKeyBase58Check: pk,
      });
      if (res.data) {
        return res.data.IsFollowing;
      }
    } catch (error) {
      console.log('Error is-following-public-key');
    }
    return defaultProfileImage;
  };

  const createRepost = async (postHashHex) => {
    try {
      const payload = {
        BodyObj: {},
        InTutorial: false,
        IsHidden: false,
        MinFeeRateNanosPerKB: 1000,
        RepostedPostHashHex: postHashHex,
        UpdaterPublicKeyBase58Check: user.deso,
        PostExtraData: {
          Node: '23',
        },
      };
      const res = await desoApi.post('submit-post', payload);
      if (res.data) {
        try {
          const res1 = await signTransaction(res.data);
          console.log(res1);
          dispatch(loadGlobalFeed());
        } catch (error) {
          console.log('err', error);
        }
      }
    } catch (error) {
      console.log('Error create repost');
    }
  };

  const createLikeStateless = async (postHashHex, isUnlike) => {
    try {
      const payload = {
        IsUnlike: isUnlike,
        LikedPostHashHex: postHashHex,
        MinFeeRateNanosPerKB: 1000,
        ReaderPublicKeyBase58Check: user.deso,
      };
      console.log(payload);
      const res = await desoApi.post('create-like-stateless', payload);
      if (res.data) {
        try {
          const res1 = await signTransaction(res.data);
          console.log(res1);
        } catch (error) {
          console.log('err', error);
        }
      }
    } catch (error) {
      console.log('Error like stateless');
    }
  };

  const updateProfile = async (imgBase64) => {
    try {
      let nodeDeso = 'https://node.deso.org/api/v0';
      if (process.env.DESO_TESTNET === 'true' || user.desoState.ProfileEntryResponse) {
        nodeDeso = null;
      }
      if (user.username) {
        const payload = {
          UpdaterPublicKeyBase58Check: user.deso,
          ProfilePublicKeyBase58Check: '',
          NewUsername: desoUsername,
          NewDescription: '',
          NewProfilePic: imgBase64 || defaultProfileImage,
          NewCreatorBasisPoints: 10000,
          MinFeeRateNanosPerKB: 1000,
          NewStakeMultipleBasisPoints: 12500,
          IsHiden: false,
        };
        const res = await desoApi.post('update-profile',
          payload,
          nodeDeso);
        if (res && res.data) {
          const signTx = await signTransaction(res.data, nodeDeso);
          console.log(signTx);
          if (signTx) {
            toast.success('Profile sync ok');
            dispatch(desoProfileComplete());
            dispatch(loadProfileData());
          }
          if (!signTx) {
            toast.error('Update Profile cancelled');
          }
        }
      }
    } catch (error) {
      toast.error(error.response.data.error);
    }
  };

  const followUser = async (pk, status) => {
    try {
      if (user.username) {
        const payload = {
          FollowedPublicKeyBase58Check: pk,
          FollowerPublicKeyBase58Check: user.deso,
          IsUnfollow: status,
          MinFeeRateNanosPerKB: 1000,
        };
        if (payload) {
          console.log(payload);
          const res = await desoApi.post('create-follow-txn-stateless', payload);
          if (res && res.data) {
            const signTx = await signTransaction(res.data);
            if (signTx) {
              console.log(signTx);
              return true;
            }
          }
        }
      }
      return false;
    } catch (error) {
      toast.error('Error following user');
      return false;
    }
  };

  const desoLogin = () => {
    launch('log-in', { accessLevelRequest: 3 })
      .subscribe(async (res) => {
        await newApi.put('user', { deso: res.publicKeyAdded });
        dispatch(loadAccount(user.id));
      });
  };

  const verifyPhoneNumber = async (publicKey) => (new Promise((resolve) => {
    try {
      launch('verify-phone-number', {
        public_key: publicKey,
      })
        .subscribe(async (approved) => {
          if (!approved.phoneNumberSuccess) {
            toast.error('Verify phone number cancelled');
          }
          dispatch(desoHasPhoneVerified());
          dispatch(loadProfileData());
          resolve(approved);
        });
    } catch (error) {
      toast.error(error.response.data.error);
    }
  }));

  const extractError = async (err) => {
    if (err != null) {
      console.log(err);
      // DeSo comment
      // Is it obvious yet that I'm not a frontend gal?
      // TODO: Error handling between BE and FE needs a major redesign.
      const rawError = err;
      if (rawError.includes('Problem decoding user public key')) {
        return 'Problem decoding public key';
      }
      return rawError;
    }
    return null;
  };

  const checkPkExists = async (publicKey) => {
    try {
      const res = await desoApi.post('get-single-profile', {
        Username: '',
        NoErrorOnMissing: true,
        PublicKeyBase58Check: publicKey,
      });
      if (res) {
        return res;
      }
      return null;
    } catch (error) {
      const res = await extractError(error.response.data.error);
      if (res) {
        return res;
      }
      return toast.error('Error fetching PK');
    }
  };

  useEffect(() => {
    if (loaded) {
      info().subscribe((res) => {
        setInfo(res);
        const id = localStorage?.getItem('identityUsers');
        if (id && id !== 'undefined') {
          setCredentials(JSON.parse(id));
        }
      });
      window.removeEventListener('message', handleMessage);
      window.addEventListener('message', handleMessage);

      return () => {
        window.removeEventListener('message', handleMessage);
      };
    }
    return () => {};
  }, [loaded]);

  // Send a new message and expect a response
  const send = (method, payload) => {
    const req = {
      id: uuidv4(),
      method,
      payload,
      service: 'identity',
    };
    const subject = new Subject();
    postMessage(req);
    outboundRequests.current[req.id] = subject;

    return subject;
  };

  const launch = (path, params = {}) => {
    let url = identityServiceURL;
    if (path) {
      url = `${url}/${path}`;
    }

    if (process.env.DESO_TESTNET === 'true') {
      // eslint-disable-next-line no-param-reassign
      params.testnet = true;
    }

    const qs = Object.keys(params)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
      .join('&');
    if (qs) {
      url += `?${qs}`;
    }

    // center the window
    const h = 1000;
    const w = 800;
    const y = window.outerHeight / 2 + window.screenY - h / 2;
    const x = window.outerWidth / 2 + window.screenX - w / 2;

    identityWindow.current = window.open(url, '_blank', `toolbar=no, width=${w}, height=${h}, top=${y}, left=${x}`);
    identityWindowSubject.current = new Subject();

    return identityWindowSubject.current;
  }; // Outgoing messages

  const burn = (payload) => send('burn', payload);

  const sign = (payload) => send('sign', {
    ...payload,
    ...credentials,
  });

  const decrypt = (payload) => send('decrypt', payload);

  const jwt = (payload) => send('jwt', payload);

  const info = () => send('info', {});

  const signTransaction = async (res, nodeDeso = null) => (new Promise((resolve) => {
    try {
      sign({ transactionHex: res.TransactionHex })
        .subscribe(async (signedRes) => {
          if (signedRes.approvalRequired) {
            launch('approve', {
              tx: res.TransactionHex,
            })
              .subscribe(async (approved) => {
                if (!approved.signedTransactionHex) {
                  toast.error('Transaction cancelled');
                  resolve(approved);
                }
                try {
                  const res2 = await desoApi.post('submit-transaction', {
                    TransactionHex: approved.signedTransactionHex,
                    nodeDeso,
                  });
                  resolve(res2.data.TxnHashHex);
                } catch (error) {
                  toast.error('Error submit-transaction');
                }
              });
          } else {
            try {
              const res2 = await desoApi.post('submit-transaction', {
                TransactionHex: signedRes.signedTransactionHex,
                nodeDeso,
              });
              resolve(res2.data.TxnHashHex);
            } catch (error) {
              toast.error('Error submit-transaction');
            }
          }
        });
    } catch (error) {
      toast.error('Error sign-transaction');
    }
  }));

  const removeUserPK = async ({ wallet }) => {
    try {
      const res = await newApi.put('user', { [wallet]: null });
      if (res) {
        toast.success('Wallet removed successfully');
        dispatch(loadAccount(user.id));
        return true;
      }
      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  return (
    <DesoContext.Provider value={
      {
        launch,
        burn,
        sign,
        decrypt,
        jwt,
        info,
        signTransaction,
        credentials,
        verifyPhoneNumber,
        updateProfile,
        getIsFollowing,
        followUser,
        createRepost,
        createLikeStateless,
        desoLogin,
        isDesoUser,
        checkPkExists,
        removeUserPK,
        supported: inf.browserSupported,
      }
    }
    >
      { loaded
        ? (
          <iframe
            title="desoindentity"
            id="identity"
            frameBorder="0"
            src={`${identityServiceURL}/embed`}
            style={{
              height: '100vh',
              width: '100vw',
              // display: inf.browserSupported ? 'none' : 'block',
              display: 'none',
            }}
          />
        ) : null }
      {children}
    </DesoContext.Provider>
  );
};

DesoContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
export default DesoContextProvider;
