import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage';
import * as Sentry from '@sentry/node';
import { RewriteFrames } from '@sentry/integrations';
import getConfig from 'next/config';
import { hotjar } from 'react-hotjar';
import reducers from '../common/data/reducers';
import { useStore } from '../store';
import App from '../src/App';
import 'react-google-places-autocomplete/dist/index.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import '../styles/global.css';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['main'],
  // whitelist: ['auth'], // place to select which state you want to persist
};
const persistedReducer = persistReducer(persistConfig, reducers);
if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  const config = getConfig();
  const distDir = `${config.serverRuntimeConfig.rootDir}/.next`;
  const integrations = [];
  if (process.env.NEXT_IS_SERVER === 'true') {
    // For Node.js, rewrite Error.stack to use relative paths, so that source
    // maps starting with ~/_next map to files in Error.stack with path
    // app:///_next
    integrations.push(
      new RewriteFrames({
        iteratee: (frame) => {
          // eslint-disable-next-line no-param-reassign
          frame.filename = frame.filename.replace(distDir, 'app:///');
          // eslint-disable-next-line no-param-reassign
          frame.filename = frame.filename.replace('.next', '_next');
          return frame;
        },
      }),
    );
  }
  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    integrations,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment: process.env.ENV,
    release: process.env.NEXT_PUBLIC_COMMIT_SHA,
  });
}

function MyApp(props) {
  const {
    pageProps: { initialReduxState },
  } = props;
  const store = useStore(initialReduxState, persistedReducer);
  const persistor = persistStore(store);

  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
    if (process.env.ENV === 'production') {
      hotjar.initialize(2919981, 6);
    }
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={<App {...props} />} persistor={persistor}>
        <App {...props} />
      </PersistGate>
    </Provider>
  );
}

MyApp.propTypes = {
  pageProps: PropTypes.object.isRequired,
};

export default MyApp;
