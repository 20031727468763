import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Head from 'next/head';
import Router from 'next/router';
import { ToastContainer } from 'react-toastify';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';
import { hotjar } from 'react-hotjar';
import { SkeletonTheme } from 'react-loading-skeleton';
import { configure } from 'mobx';
import OneSignal from 'react-onesignal';
import * as gtag from './helpers/gtag';
import newApi from '../common/api/newApi';
import api from '../common/api';
import { MeetingSessionContextProvider } from './contexts/MeetingSessionContext';
import {
  checkSession,
  loadDesoAppStateData,
} from '../common/data/actions';
import { NewsContextProvider } from './contexts/NewsContext';
import InboxConnectionsContextProvider from './contexts/InboxConnectionsContext';
import DesoContextProvider from './contexts/DesoContext';
import { VideoContextProvider } from './contexts/VideoContext';
import DmChatStore from '../common/mobx/stores/DmChatStore';
import { COLOUR_BLACK_5, COLOUR_BLACK_80 } from './shared/colours';

if (!String.prototype.matchAll) {
  // eslint-disable-next-line no-extend-native
  String.prototype.matchAll = (rx) => {
    // eslint-disable-next-line no-param-reassign
    if (typeof rx === 'string') rx = new RegExp(rx, 'g'); // coerce a string to be a global regex
    // eslint-disable-next-line no-param-reassign
    rx = new RegExp(rx); // Clone the regex so we don't update the last index on the regex passed us
    let cap = []; // the single capture
    const all = []; // all the captures (return this)
    // eslint-disable-next-line no-cond-assign
    while ((cap = rx.exec(this)) !== null) all.push(cap); // execute and add
    return all; // profit!
  };
}

const App = (props) => {
  const { Component, pageProps } = props;
  const dispatch = useDispatch();
  const authLoaded = useSelector((state) => state.auth.loaded);
  const authToken = useSelector((state) => state.auth.token);
  const userAuth = useSelector((state) => state.auth.userAuth);
  const lightMode = useSelector((state) => state.main.lightMode);
  const completed = useSelector((state) => state.onboarding.completed);
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.account.user);
  const fingerPrint = useRef(null);
  const session = useRef(null);

  const APP_ID = process.env.ONESIGNAL_APP_ID;

  useEffect(() => {
    document.body.className = lightMode ? 'light' : 'dark';
    if (!token) {
      document.body.className = 'dark';
    }
  }, [lightMode, token]);

  const chatStoreMessageEventListener = (event) => {
    DmChatStore.handleMessageEvent(event);
  };

  const pathChangeHandler = (path) => {
    if ((window.location.pathname === '/messaging' && path !== '/messaging')
      || (window.location.pathname.includes('/room') && !path.includes('/room'))) {
      DmChatStore.resetChatStore(true);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      dispatch(checkSession());
      dispatch(loadDesoAppStateData());
      window.removeEventListener('chatStoreAddMessageEvent', chatStoreMessageEventListener);
      window.addEventListener('chatStoreAddMessageEvent', chatStoreMessageEventListener);
      Router.events.off('routeChangeStart', pathChangeHandler);
      Router.events.on('routeChangeStart', pathChangeHandler);
    }

    configure({
      enforceActions: 'always',
      computedRequiresReaction: true,
      reactionRequiresObservable: true,
      observableRequiresReaction: true,
      disableErrorBoundaries: true,
    });

    return () => {
      window.removeEventListener('chatStoreAddMessageEvent', chatStoreMessageEventListener);
      Router.events.off('routeChangeStart', pathChangeHandler);
    };
  }, []);

  useEffect(() => {
    if (token && user && completed) {
      try {
        OneSignal.init({
          appId: APP_ID,
          safari_web_id: '',
          allowLocalhostAsSecureOrigin: true,
          promptOptions: {
            slidedown: {
              enabled: true,
              actionMessage: "We'd like to show you notifications.",
              acceptButtonText: 'ALLOW',
              cancelButtonText: 'NO, THANKS',
            },
          },
          notifyButton: {
            /* Hide Bell */
            enable: false,
          },
        });
        OneSignal.setExternalUserId(user.id);
      } catch (error) {
        console.error(error);
      }
    }
  }, [token, user]);

  useEffect(() => {
    if (user) {
      DmChatStore.initSetup();
    }

    return () => {
      DmChatStore.unsubscribeChatSubscriptions();
    };
  }, [user]);

  useEffect(() => {
    api.setToken(authToken);
    newApi.setToken(authToken);
  }, [authToken]);

  const handleRouteChange = (url) => {
    if (!url.includes('/room/')) {
      sessionStorage.setItem('entre-history', url);
    }

    setTimeout(async () => {
      window.analytics.page(url);
      if (process.env.ENV === 'production') {
        hotjar.stateChange(url);
      }
      gtag.pageview(url);
      const eventData = {
        session_id: session.current,
        uuid: fingerPrint.current.visitorId,
        event_time: (new Date()).toISOString(),
        source: 'web',
        event_type: 'traffic',
        url_origin: window.location.origin,
        url_path: url,
        referrer: document.referrer,
        os_platform: window.navigator.platform,
        device_lang: window.navigator.language,
        extra_data: {},
      };
      const apiUrl = 'public/track';
      if (userAuth && userAuth.uid) {
        eventData.user_id = userAuth.uid;
      }
      try {
        const res = await newApi.post(apiUrl, { eventData });
        return res;
      } catch (err) {
        Swal.fire({
          title: 'Oops...',
          text: 'Internal Server Error. Please try again',
          background: lightMode ? COLOUR_BLACK_5 : COLOUR_BLACK_80,
          color: lightMode ? COLOUR_BLACK_80 : COLOUR_BLACK_5,
          icon: 'error',
        });
        return err;
      }
    }, 200); // Wait for react <Head> to update title before tracking
  };

  useEffect(() => {
    if (authLoaded && process.env.ENV === 'production') {
      const asyncFP = async () => {
        const fpCache = localStorage?.getItem('fpCache');
        if (!fpCache) {
          const fp = await FingerprintJS.load({
            // apiKey: process.env.FINGERPRINT_KEY,
          });
          fingerPrint.current = await fp.get();
          localStorage?.setItem('fpCache', JSON.stringify(fingerPrint.current));
        } else {
          fingerPrint.current = JSON.parse(fpCache);
        }
        handleRouteChange(window.location.pathname);
        Router.events.on('routeChangeComplete', handleRouteChange);
      };
      if (sessionStorage) {
        try {
          session.current = sessionStorage.getItem('entre-session');
        } catch (error) {
          console.error(error);
        }
      }
      if (!session.current) {
        session.current = uuidv4();
        sessionStorage.setItem('entre-session', session.current);
      }
      asyncFP();
      return () => {
        Router.events.off('routeChangeComplete', handleRouteChange);
      };
    }
    return () => { };
  }, [authLoaded]);

  api.setToken(authToken);
  newApi.setToken(authToken);

  return (
    <>
      <Head>
        <title>Entre</title>
        <meta name="facebook-domain-verification" content="p53rw3w2sk5ou2cyq8tl38wt2lb517" />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <script
          type="text/javascript"
          src={`https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_MAPS_KEY}&libraries=places`}
          async
        />

        <link
          rel="stylesheet"
          type="text/css"
          charSet="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
        <script
          src="https://kit.fontawesome.com/1346718fb0.js"
          crossOrigin="anonymous"
          async
        />
        <meta property="og:type" content="website" />
        <meta key="title" property="og:title" content="Entre - Professional network for entrepreneurs, creators, & investors." />
        <meta key="description" property="og:description" content="An entrepreneur community with the vision to provide the next generation of business networking for the future of work and the creator economy." />
        <meta name="keywords" content="Entrepreneur, Entrepreneur Network, Entrepreneur Community, Startup network, startup community, Creator Economy, Creator Community, Freelancers, Venture Capitalists, Angel Investors, Start a business, How to start a business, Startup jobs, Startup events, Cofounder, Find a cofounder, Find a mentor, Find an Investor, meet entrepreneurs, host virtual event, host virtual meeting, entrepreneur events, tech events, entrepreneurship, find gigs, freelancing jobs, online business" />
        <meta property="og:site_name" content="Entre" />
        <meta key="image" property="og:image" content="https://media.joinentre.com/share.png" />
        <meta property="og:image:alt" content="Entre logo" />
        <meta property="og:locale" content="en_US" />
        <meta name="twitter:text:title" content="Entre" />
        <meta name="twitter:image" content="https://media.joinentre.com/share_twitter.png" />
        <meta name="twitter:image:alt" content="Entre logo" />
        <meta name="twitter:card" content="summary" />
      </Head>
      <SkeletonTheme color="#D9D9D9" highlightColor="#E9E9E9">
        <DesoContextProvider>
          <MeetingSessionContextProvider>
            <VideoContextProvider>
              <InboxConnectionsContextProvider>
                <NewsContextProvider>
                  <Component {...pageProps} />
                </NewsContextProvider>
              </InboxConnectionsContextProvider>
            </VideoContextProvider>
          </MeetingSessionContextProvider>
        </DesoContextProvider>
      </SkeletonTheme>
      <ToastContainer limit={1} position="bottom-center" toastClassName="toast" />
    </>
  );
};

App.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object.isRequired,
};

export default App;
