/* eslint-disable no-alert, no-console, linebreak-style, jsx-a11y/anchor-is-valid */
import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { parseText } from '../shared/utilities';
import newApi from '../../common/api/newApi';
import { updateETHprice } from '../../common/data/actions/ethActions';

const NewsContext = createContext(null);

export const NewsContextProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [stockNews, setStockNews] = useState([]);
  const [cryptoNews, setCryptoNews] = useState([]);
  const [trendingNews, setTrendingNews] = useState([]);
  const [trendingPage, setTrendingPage] = useState(1);
  const [loadingTrendingNews, setLoadingTrendingNews] = useState(false);

  const loadStockMarketNews = (stockArray) => {
    if (stockNews.length) return;
    newApi.get(`finance/quotes?list=${encodeURI(stockArray.join(','))}`)
      .then((response) => {
        const stocksRes = [];
        if (response) {
          if (response.data && Array.isArray(response.data)) {
            response.data.forEach((el, i) => {
              let { price } = el;
              if (typeof el.price === 'object') {
                price = el.price.raw;
              }
              stocksRes.push({
                symbol: el.symbol,
                name: parseText(el.name, 10),
                changePercent: (el.changePercent * 100).toFixed(2),
                price: price.toFixed(2),
                id: i,
              });
            });
            setStockNews(stocksRes);
          }
        }
      });
  };

  const loadCryptoMarketNews = (cryptoArray) => {
    if (cryptoNews.length) return;
    newApi.get(`finance/quotes?list=${encodeURI(cryptoArray.join(','))}`)
      .then((response) => {
        const cryptoRes = [];
        if (response && response.data && Array.isArray(response.data)) {
          response.data.forEach((el, i) => {
            let { price } = el;
            if (typeof el.price === 'object') {
              price = el.price.raw;
            }
            cryptoRes.push({
              symbol: el.symbol,
              name: parseText(el.name, 10),
              changePercent: (el.changePercent * 100).toFixed(2),
              price: price.toFixed(2),
              id: i,
            });
          });
          dispatch(updateETHprice(response.data.filter((coin) => coin.symbol === 'ETH-USD')));
        }
        setCryptoNews(cryptoRes);
      });
  };

  const reArrangeMarketNews = (data, direction) => {
    if (data === 'stock') {
      if (direction === 'forward') {
        setStockNews((prevData) => [
          ...prevData?.slice(1),
          prevData[0],
        ]);
      } else {
        setStockNews((prevData) => [
          prevData[prevData.length - 1],
          ...prevData?.slice(0, prevData.length - 1),
        ]);
      }
    } else if (data === 'crypto') {
      if (direction === 'forward') {
        setCryptoNews((prevData) => [
          ...prevData?.slice(1),
          prevData[0],
        ]);
      } else {
        setCryptoNews((prevData) => [
          prevData[prevData.length - 1],
          ...prevData?.slice(0, prevData.length - 1),
        ]);
      }
    }
  };

  const loadMoreTrendingNews = async () => {
    if (loadingTrendingNews) return;
    setLoadingTrendingNews(true);
    newApi.axiosOther.get(`/api/news?section=general&items=5&page=${trendingPage}`)
      .then((response) => {
        if (response && response.data) {
          const { data } = response.data;
          if (Array.isArray(data)) {
            setTrendingNews((prevData) => [
              ...prevData,
              ...data,
            ]);
            setTrendingPage((prevPage) => prevPage + 1);
          }
        }
        setLoadingTrendingNews(false);
      });
  };

  const loadLessTrendingNews = () => {
    setLoadingTrendingNews(true);
    setTrendingNews(trendingNews?.slice(0, trendingNews.length - 5));
    setTrendingPage((prevPage) => prevPage - 1);
    setLoadingTrendingNews(false);
  };

  return (
    <NewsContext.Provider value={{
      loadStockMarketNews,
      loadCryptoMarketNews,
      stockNews,
      cryptoNews,
      reArrangeMarketNews,
      trendingNews,
      trendingPage,
      loadingTrendingNews,
      loadMoreTrendingNews,
      loadLessTrendingNews,
    }}
    >
      {children}
    </NewsContext.Provider>
  );
};

NewsContextProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export default NewsContext;
