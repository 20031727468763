/* eslint-disable import/prefer-default-export */

export const getRole = (roles) => {
  if (!roles) return 'audience';
  if (roles.owner) {
    return 'creator';
  }
  if (roles.host) {
    return 'host';
  }
  if (roles.speaker) {
    return 'speaker';
  }
  return 'audience';
};

export const getRoleWeight = (roles) => {
  if (!roles) return 0;
  if (roles.owner) {
    return 3;
  }
  if (roles.host) {
    return 2;
  }
  if (roles.speaker) {
    return 1;
  }
  return 0;
};
