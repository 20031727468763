/* eslint-disable consistent-return, jsx-a11y/media-has-caption */
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

function AudioInputDevice() {
  const session = useSelector((state) => state.rooms.session);
  const isJoining = useSelector((state) => state.rooms.isJoining);
  const isLeaving = useSelector((state) => state.rooms.isLeaving);
  const selectedAudio = useSelector((state) => state.rooms.selectedAudio);
  const role = useSelector((state) => state.rooms.role);

  useEffect(() => {
    if (session && isLeaving) {
      return session.audioVideo.chooseAudioInputDevice(null);
    }
    if (isJoining || !session || session.error || isLeaving || role === 'audience') return;
    session.audioVideo
      .listAudioInputDevices()
      .then((devices) => {
        if (role !== 'audience') {
          if (devices.length === 0) {
            console.warn('No audio input devices detected.');
            return;
          }
          if (selectedAudio.value === undefined) {
            console.log('Registering audio input device:', devices[0].deviceId);
            return session.audioVideo.chooseAudioInputDevice(devices[0].deviceId);
          }
          console.log('Registering audio input device:', selectedAudio.value);
          return session.audioVideo.chooseAudioInputDevice(selectedAudio.deviceId);
        }
        return session.audioVideo.chooseAudioInputDevice(null);
      })
      .catch((err) => {
        console.error(
          'Error occurred attempting to list audio/video devices.',
          err,
        );
      });
    const observer = {
      audioInputsChanged: async (inputDevices) => {
        console.debug('Audio inputs updated: ', inputDevices);
        try {
          await session.audioVideo.chooseAudioInputDevice('default');
        } catch (e) {
          console.debug('failed to chooseAudioInputDevice', e);
        }
      },
    };
    session.audioVideo.addDeviceChangeObserver(observer);
    return () => {
      session.audioVideo.removeDeviceChangeObserver(observer);
    };
  }, [session, isJoining, selectedAudio, role]);

  return null;
}

export default AudioInputDevice;
