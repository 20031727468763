/* eslint-disable import/prefer-default-export */
import { hotjar } from 'react-hotjar';
import { ACCOUNT } from './types';
import { getStore } from '../../../store';
import firebase from '../../../src/firebase';
import newApi from '../../api/newApi';
import desoApi from '../../api/deso';
import { desoHasPhoneVerified, desoProfileComplete } from './desoActions';
import PubnubInstance from '../../mobx/shared/PubnubInstance';

const loadAccountFail = (dispatch, error) => {
  dispatch({
    type: ACCOUNT.LOAD_ACCOUNT_FAIL,
    payload: error,
  });
};

const loadAccountSuccess = (dispatch, account) => {
  if (account && account.id) {
    const properties = {
      email: account.email,
      fullName: account.fullName,
      username: account.username,
    };
    if (process.env.ENV === 'production') {
      hotjar.identify(account.id, properties);
    }
    window.analytics.identify(account.id, properties);
    PubnubInstance.setUser(account);
  }
  dispatch({
    type: ACCOUNT.LOAD_ACCOUNT_SUCCESS,
    payload: account || [],
  });
};

export const loadAccount = (userId) => async (dispatch) => {
  const referredBy = localStorage?.getItem('referredBy');
  let url = `user/${userId}`;
  if (referredBy) {
    url = `user/${userId}?referredBy=${referredBy}`;
  }
  const { deso } = getStore().getState();
  const resp = await newApi.get(url);
  localStorage?.removeItem('referredBy');
  if (resp.data.data) {
    const user = resp.data.data[0];
    if (user && user.deso && !deso.loading) {
      try {
        const pk = user.deso;
        const desoResp = await desoApi.post('get-users-stateless', {
          PublicKeysBase58Check: [pk], SkipHodlings: true,
        });
        loadAccountSuccess(dispatch, {
          ...user,
          desoState: desoResp.data.UserList[0],
        });
        if (desoResp.data.UserList[0].ProfileEntryResponse) {
          dispatch(desoProfileComplete());
        }
        if (desoResp.data.UserList[0].HasPhoneNumber) {
          dispatch(desoHasPhoneVerified());
        }
      } catch (e) {
        if (e) {
          console.log('err', e);
        }
      }
    } else {
      loadAccountSuccess(dispatch, user);
    }
  } else {
    loadAccountFail(dispatch, resp.data.errorMessage);
  }
  return resp;
};

export const loadIndustries = () => async (dispatch) => {
  const { account } = getStore().getState();
  if (account.industriesLoading) return;
  dispatch({
    type: ACCOUNT.LOAD_INDUSTRIES,
  });
  const resp = await newApi.get('data/user/interests');
  const industries = resp.data;

  if (resp) {
    dispatch({
      type: ACCOUNT.LOAD_INDUSTRIES_SUCCESS,
      payload: industries || [],
    });
  } else {
    // loadIndustriesFail(dispatch, resp);
  }
};

export const loadTitles = () => async (dispatch) => {
  const { account } = getStore().getState();
  if (account.titlesLoading) return;
  dispatch({
    type: ACCOUNT.LOAD_TITLES,
  });
  const resp = await newApi.get('data/user/roles');
  const role = resp.data;
  if (resp) {
    dispatch({
      type: ACCOUNT.LOAD_TITLES_SUCCESS,
      payload: role || [],
    });
  } else {
    // loadIndustriesFail(dispatch, resp);
  }
};

export const loadConnections = (offset = 0, limit = 10) => async (dispatch) => {
  const { account } = getStore().getState();
  if (account.connectionsLoading) return;
  dispatch({
    type: ACCOUNT.LOAD_CONNECTIONS,
  });
  const resp = await newApi.get(`user/connections?limit=${limit}&offset=${offset}`);
  const connections = resp.data;

  if (resp) {
    dispatch({
      type: ACCOUNT.LOAD_CONNECTIONS_SUCCESS,
      payload: connections || [],
    });
  } else {
    // loadIndustriesFail(dispatch, resp);
  }
};

export const updateUserEmail = (email) => async (dispatch) => {
  const { auth } = getStore().getState();
  const user = firebase.auth().currentUser;
  try {
    await user.updateEmail(email).then(() => ({ message: 'Update successful' })).catch((error) => error);
    const response = await newApi.put('user', { email });
    if (response.data.error) {
      return { error: 'Cannot update account info' };
    }
    await dispatch(loadAccount(auth.userAuth.uid));
    return { message: 'Update successful' };
  } catch (err) {
    return { error: 'Cannot update email, it might be already in use' };
  }
};

export const deleteAccount = () => async () => {
  try {
    const response = await newApi.delete('user');
    if (response.data.error) {
      return { error: 'Cannot delete account' };
    }
    firebase.auth().signOut();
    return { message: 'Account was deleted' };
  } catch (err) {
    return { error: 'Invalid current password' };
  }
};

export const updateAccount = (updateInfo) => async (dispatch) => {
  const { auth } = getStore().getState();
  dispatch({
    type: ACCOUNT.UPDATE_ACCOUNT,
  });
  const data = updateInfo;
  const response = await newApi.put('user', data);
  if (response.data.error) {
    dispatch({
      type: ACCOUNT.UPDATE_ACCOUNT_FAIL,
      payload: response,
    });
  } else {
    await dispatch(loadAccount(auth.userAuth.uid));
    dispatch({
      type: ACCOUNT.UPDATE_ACCOUNT_SUCCESS,
      payload: response,
    });
  }
  return response.status;
};

export const checkEmail = async (email) => {
  const strategy = await firebase.auth().fetchSignInMethodsForEmail(email);
  return strategy.length > 0;
};

export const createStripeIntent = async ({ payload }) => {
  const response = await newApi.post('stripe/pay-ticket', payload);
  return response;
};

export const createStripeCustomer = async (email, metadata) => {
  const response = await newApi.post('stripe/create-customer', { email, metadata });
  return response;
};

export const createStripeSubscription = async (customerId, paymentMethodId, priceId) => {
  const response = await newApi.post('stripe/create-subscription', {
    customerId,
    paymentMethodId,
    priceId,
    metadata: {
      origin: window.location.origin,
    },
  });
  return response;
};

export const getStripeCard = async (paymentMethodId) => {
  const response = await newApi.post('stripe/retrieve-customer-payment-method', { paymentMethodId });
  return response;
};

export const setPro = (isPro, paypalTransaction) => async (dispatch) => {
  const { auth } = getStore().getState();
  await newApi.put('user/entrepro', { isPro });
  await newApi.post('payment/subscribe', paypalTransaction);
  await dispatch(loadAccount(auth.userAuth.uid));
};

const loadAccountPostSuccess = (dispatch, posts) => {
  dispatch({
    type: ACCOUNT.LOAD_ACCOUNT_POST_SUCCESS,
    payload: posts || [],
  });
};

export const loadAccountPosts = (userId) => async (dispatch) => {
  const { account } = getStore().getState();
  if (account.postLoading) return;
  dispatch({
    type: ACCOUNT.LOAD_ACCOUNT_POST,
  });
  const resp = await newApi.get(`user/${userId}/post`);
  if (resp.data.data) {
    const posts = resp.data.data;
    loadAccountPostSuccess(dispatch, posts);
  } else {
    loadAccountFail(dispatch, resp.data.errorMessage);
  }
};

export const verifyPhoneNumber = (phoneNumber) => async () => {
  try {
    const res = await newApi.post('user/code/send', { phone_number: phoneNumber });
    return res;
  } catch (err) {
    return { err };
  }
};

export const codeValidate = (codeNumber) => async (dispatch) => {
  const { auth } = getStore().getState();
  try {
    const res = await newApi.post('user/code/validate', { code: codeNumber });
    await dispatch(loadAccount(auth.userAuth.uid));
    return res;
  } catch (err) {
    return { err };
  }
};
