import axios from 'axios';

const BASE_URL = `${process.env.DESO_NODE_URL}/api/v0`;

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 5000,
  params: {},
  headers: {
    'Content-Type': 'application/json',
  },
});

export default {
  async get(resource, URL = null) {
    return axiosInstance.get(`${URL || BASE_URL}/${resource}`);
  },
  async post(resource, body, URL = null) {
    if (URL) {
      return axiosInstance.post(`${URL}/${resource}`, body);
    }
    return axiosInstance.post(`${BASE_URL}/${resource}`, body);
  },
  async patch(resource, body) {
    return axiosInstance.patch(`${BASE_URL}/${resource}`, body);
  },
  async put(resource, body) {
    return axiosInstance.put(`${BASE_URL}/${resource}`, body);
  },
  async delete(resource, body) {
    return axiosInstance.delete(`${BASE_URL}/${resource}`, body);
  },
};
