import { createAction } from 'redux-actions';
import { getStore } from '../../../store';
import api from '../../api/newApi';

export const loadRecordingsComplete = createAction('LOAD_RECORDINGS_SUCCESS');
export const loadRecordings = createAction('LOAD_RECORDINGS', async (limit = 10, offset = 0) => {
  const url = `user/recordings?limit=${limit}&offset=${offset}`;
  const resp = await api.get(url);
  if (resp && resp.data.data) {
    getStore().dispatch(loadRecordingsComplete(resp.data.data));
  }
});
