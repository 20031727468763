/* eslint-disable import/prefer-default-export */
import { MAIN } from './types';

export const dismissAppModal = () => async (dispatch) => {
  dispatch({
    type: MAIN.DISMISS_APP_MODAL,
  });
};

export const dismissVideoModal = () => async (dispatch) => {
  dispatch({
    type: MAIN.DISMISS_VIDEO_MODAL,
  });
};

export const switchPalleteColor = () => (dispatch) => {
  dispatch({
    type: MAIN.SWITCH_PALLETE_COLOR,
  });
};

export const resetAppModal = () => (dispatch) => {
  dispatch({
    type: MAIN.RESET_DISMISS_APP_MODAL,
  });
};

export const resetRedirectState = () => (dispatch) => {
  dispatch({
    type: MAIN.RESET_REDIRECT,
  });
};

export const redirecToBlog = () => (dispatch) => {
  dispatch({
    type: MAIN.REDIRECT_TO_BLOG,
  });
};

export const resetRedirecToBlog = () => (dispatch) => {
  dispatch({
    type: MAIN.RESET_REDIRECT_TO_BLOG,
  });
};
