export const COLOUR_PRIMARY = '#006DFF';
export const COLOUR_SECONDARY = '#28F9DA';
export const COLOUR_GRAYISH_NAVY_BLUE = '#18243a';
export const COLOUR_NAVY_BLUE = '#101C3D';
export const COLOUR_WHITE = '#fff';
export const COLOUR_BLACK_5 = '#f2f2f2';
export const COLOUR_BLACK_10 = '#e6e6e6';
export const COLOUR_BLACK_25 = '#c0c0c0';
export const COLOUR_BLACK_50 = '#808080';
export const COLOUR_BLACK_75 = '#404040';
export const COLOUR_BLACK_80 = '#181818';
export const COLOUR_BLACK_88 = '#101010';
export const COLOUR_BLACK_90 = '#B9B9B9';
export const COLOUR_BLACK_100 = '#000';
export const COLOUR_GREEN = '#0EDA95';
export const COLOUR_RED = '#FF1616';
export const COLOUR_YELLOW = '#F2DD22';
