import { createAction } from 'redux-actions';
import firebase from '../../../src/firebase';
import { getStore } from '../../../store';
import newApi from '../../api/newApi';

const firestore = firebase.firestore();

export const setSession = createAction('SET_SESSION');
export const updateRoomState = createAction('UPDATE_ROOM_STATE');
export const addCameraMap = createAction('ADD_CAMERA_MAP');
export const removeCameraMap = createAction('REMOVE_CAMERA_MAP');
export const removeContentMap = createAction('REMOVE_CONTENT_MAP');
export const resetRoomState = createAction('RESET_ROOM_STATE');
export const updateParticipants = createAction('UPDATE_ROOM_PARTICIPANTS');
export const attendeeLeft = createAction('ATTENDEE_LEFT');
export const attendeeJoined = createAction('ATTENDEE_JOINED');
export const volumeChanged = createAction('VOLUME_CHANGED');
export const replaceLastInRoomMessage = createAction('REPLACE_LAST_IN_ROOM_MESSAGE');

export const toggleMute = createAction('TOGGLE_MUTE', async () => {
  const { rooms: { session } } = getStore().getState();
  const muted = await session.audioVideo.realtimeIsLocalAudioMuted();
  if (!muted) {
    // Mute
    await session.audioVideo.realtimeMuteLocalAudio();
  } else {
    // Unmute
    await session.audioVideo.realtimeUnmuteLocalAudio();
  }
});

export const startCamera = createAction('START_CAMERA', async () => {
  const { rooms: { session } } = getStore().getState();
  const { rooms: { selectedVideo } } = getStore().getState();
  const devices = await session.audioVideo.listVideoInputDevices();
  if (devices.length === 0) {
    console.warn('No video input devices detected.');
    return;
  }
  console.log('Registering video input device:', selectedVideo.deviceId);
  try {
    // When user join skipping preview waitingroom, choose the 1st camera
    if (selectedVideo.deviceId === undefined) {
      await session.audioVideo.chooseVideoInputDevice(devices[0].deviceId);
    } else {
      await session.audioVideo.chooseVideoInputDevice(selectedVideo.deviceId);
    }
    getStore().dispatch(updateRoomState({ isSharingCamera: true }));
  } catch (e) {
    if (e) {
      console.log('Permission denied', e);
    }
  }
  session.audioVideo.startLocalVideoTile();
});

export const toggleCamera = createAction('TOGGLE_CAMERA', async () => {
  const { rooms: { isSharingCamera } } = getStore().getState();
  const { rooms: { session } } = getStore().getState();
  if (isSharingCamera) {
    await session.audioVideo.stopLocalVideoTile();
    getStore().dispatch(updateRoomState({ isSharingCamera: false }));
  } else {
    startCamera();
  }
});

export const updateMember = createAction('UPDATE_MEMBER', async (userId, nRole) => {
  const { rooms: { roomId } } = getStore().getState();
  let newRole = {};
  switch (nRole) {
    case 'host':
      newRole = { host: true, speaker: false };
      break;
    case 'speaker':
      newRole = { host: false, speaker: true };
      break;
    case 'audience':
      newRole = { host: false, speaker: false };
      break;
    default:
      newRole = {};
      break;
  }
  if (Object.keys(newRole).length) {
    await firestore.collection('rooms').doc(roomId).update({
      [`participants.${userId}.roles`]: newRole,
      [`participants.${userId}.handRaised`]: false,
    });
  }
});

export const handRaisedMember = createAction('HAND_RAISED_MEMBER', async (userId, raise) => {
  const { rooms: { roomId } } = getStore().getState();
  await firestore.collection('rooms').doc(roomId).update({
    [`participants.${userId}.handRaised`]: raise,
  });
});

export const presentReaction = createAction('PRESENT_REACTION', async (userId, key) => {
  const { rooms: { roomId } } = getStore().getState();
  await firestore.collection('rooms').doc(roomId).update({
    [`participants.${userId}.currentReaction`]: key,
  });
  await new Promise((r) => setTimeout(r, 6000));
  await firestore.collection('rooms').doc(roomId).update({
    [`participants.${userId}.currentReaction`]: firebase.firestore.FieldValue.delete(),
  });
});

export const kickMember = createAction('KICK_MEMBER', async (userId) => {
  const { rooms: { roomId } } = getStore().getState();
  const resp = await newApi.post('room/kick', { userId, roomId });
  if (resp.data.message) {
    return resp.data.message;
  } return false;
});

export const resetHostKick = createAction('RESET_HOST_KICK', async (userId) => {
  const { rooms: { roomId } } = getStore().getState();
  await firestore.collection('rooms').doc(roomId).update({
    [`hostKicked.${userId}`]: firebase.firestore.FieldValue.delete(),
  });
});
