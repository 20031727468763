import React from 'react';
import PropTypes from 'prop-types';

const Emoji = ({
  children,

}) => (

  <span style={{ fontSize: '1rem' }}>{children}</span>

);

Emoji.propTypes = {
  children: PropTypes.string.isRequired,
};

export default Emoji;
