/* eslint-disable no-underscore-dangle */

import { handleActions } from 'redux-actions';
import {
  loadFeed,
  resetFeed,
  setCurrentFeedPage,
  setCurrentSearch,
  loadFeedComplete,
  loadPosts,
  resetPosts,
  loadPostsComplete,
  blockUserComplete,
  deletePostComplete,
  setFilter,
  setVoteComplete,
  addCommentComplete,
  deleteCommentComplete,
  setPostTipComplete,
  addCommentFailed,
} from '../actions/homeActions';

const INITIAL_STATE = {
  loadingFeed: true,
  loadingPosts: false,
  hasMorePosts: false,
  hasMoreFeed: false,
  curPlayingVidId: '',
  currentFeedFilter: 'foryou',
  currentFeedPage: 0,
  currentSearch: '',
  posts: [],
  feed: [],
};

const reducer = handleActions(
  {
    [setFilter]: (state, { payload }) => ({ ...state, currentFeedFilter: payload }),
    [loadFeed]: (state) => ({ ...state, loadingFeed: true }),
    [resetFeed]: (state) => ({ ...state, feed: INITIAL_STATE.feed, currentFeedPage: 0 }),
    [setCurrentFeedPage]: (state, { payload }) => ({ ...state, currentFeedPage: payload }),
    [setCurrentSearch]: (state, { payload }) => ({ ...state, currentSearch: payload }),
    [loadFeedComplete]: (state, { payload }) => {
      let feed = payload;
      if (payload.length && state.feed.length > 0) {
        const currentId = state.feed.map((e) => e.id || e._id);
        const filterPayload = payload.filter((e) => !currentId.includes(e.id || e._id));
        feed = [...state.feed, ...filterPayload];
      } else if (payload.length === 0 && state.feed.length > 0) {
        feed = state.feed;
      }

      return {
        ...state,
        feed,
        hasMoreFeed: payload.length > 0,
        loadingFeed: false,
      };
    },
    [loadPosts]: (state) => ({ ...state, loadingPosts: true }),
    [resetPosts]: (state) => ({ ...state, posts: INITIAL_STATE.posts }),
    [loadPostsComplete]: (state, { payload }) => {
      let posts = payload;
      if (payload.length && state.posts.length > 0) {
        posts = [...state.posts, ...payload];
      } else if (payload.length === 0 && state.posts.length > 0) {
        posts = state.posts;
      }

      return {
        ...state,
        posts,
        hasMorePosts: payload.length > 0,
        loadingPosts: false,
      };
    },
    [blockUserComplete]: (state, { payload }) => ({
      ...state,
      posts: state.posts.filter((post) => post.author.id !== payload),
      feed: state.feed.filter((post) => post.author.id !== payload),
      // comments: state.comments.filter((comment) => comment.author.id !== payload),
    }),
    [deletePostComplete]: (state, { payload }) => ({
      ...state,
      posts: state.posts.filter((post) => post.id !== payload),
      feed: state.feed.filter((post) => post.id !== payload),
    }),
    [setVoteComplete]: (state, { payload: { postId, isFavorite } }) => {
      // console.log('////', postId, isFavorite);
      const p = state.feed.find((post) => post.id === postId);
      if (!p) {
        return state;
      }
      if (isFavorite === 1) {
        p.favoriteCount += 1;
        p.isFavorite = true;
      } else {
        p.favoriteCount -= 1;
        p.isFavorite = false;
      }
      return state;
    },
    [setPostTipComplete]: (state, { payload: { postId, amount } }) => {
      // console.log('////', postId, amount);
      const p = state.feed.find((post) => post.id === postId);
      if (!p) {
        return state;
      }
      if (p?.deso) {
        p.deso.DiamondCount += amount;
        p.deso.PostEntryReaderState.DiamondLevelBestowed += amount;
      }

      return state;
    },
    [deleteCommentComplete]: (state, { payload: { postId } }) => {
      const p = state.feed.find((post) => post.id === postId);
      if (p) {
        p.commentCount -= 1;
      }
      return state;
    },
    [addCommentComplete]: (state, { payload: { postId } }) => {
      const p = state.feed.find((post) => post.id === postId);
      if (p) {
        p.commentCount += 1;
      }
      return state;
    },
    [addCommentFailed]: (state) => state,
  },
  INITIAL_STATE,
);

export default reducer;
