import router from 'next/router';
import { toast } from 'react-toastify';
import firebase from '../../../src/firebase';
import { getStore } from '../../../store';
import { ONBOARDING } from './types';
import api from '../../api/newApi';
import { loadAccount } from './accountActions';
import { redirectAfterOnboarding } from './authActions';

export const submitOnboarding = () => async (dispatch) => {
  dispatch({
    type: ONBOARDING.SUBMIT,
  });
  const { onboarding, auth, main } = getStore().getState();
  const updateInfo = {
    company: onboarding.company || '',
    titleName: onboarding.titleName,
    fullName: onboarding.fullName,
    industry: onboarding.industries || '',
    title: onboarding.titles,
    roles: onboarding.role || '',
    location: onboarding.location || '',
    lookingFor: onboarding.interests || '',
    username: onboarding.username,
    images: onboarding.profile || '',
    onboardingComplete: true,
    email: onboarding.email || '',
  };
  const response = await api.put('user', updateInfo);
  await new Promise((r) => setTimeout(r, 2000));
  if (response.data.error) {
    console.log(response.data.error);
    toast.error(response.data.error);
  } else {
    if (onboarding.email) {
      const { currentUser } = firebase.auth();
      currentUser.updateEmail(onboarding.email);
    }
    dispatch(loadAccount(auth.userAuth.uid));
    dispatch({
      type: ONBOARDING.COMPLETED,
    });
    if (main.redirect) {
      router.push(main.redirect);
      dispatch(redirectAfterOnboarding(null));
    }
  }
};

export const updateLocation = (location) => async (dispatch) => {
  dispatch({
    type: ONBOARDING.UPDATE_LOCATION,
    location,
  });
};

export const getClientLocation = () => async (dispatch) => {
  const { onboarding } = getStore().getState();
  const selectedLocation = onboarding.location;
  if (
    onboarding.fetchingLocation === false && !(
      selectedLocation
      && selectedLocation.city
      && selectedLocation.state
      && selectedLocation.country
    )) {
    dispatch({ type: ONBOARDING.FETCHING_LOCATION });
    try {
      const geoResponse = await api.axiosOther.post(`https://www.googleapis.com/geolocation/v1/geolocate?key=${process.env.GOOGLE_MAPS_KEY}`);
      if (geoResponse.status === 200) {
        const { location } = geoResponse.data;
        const geoCodeResponse = await api.axiosOther.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${location.lat},${location.lng}&result_type=locality|administrative_area_level_3&key=${process.env.GOOGLE_MAPS_KEY}`);
        const { data: { results } } = geoCodeResponse;
        if (results.length > 0) {
          const [{ address_components: addressComponents }] = results;
          const locResult = {};
          for (let i = 0; i < addressComponents.length; i += 1) {
            const component = addressComponents[i];
            const types = new Set(component.types);
            if (types.has('country')) {
              locResult.country = component.short_name;
            }
            if (types.has('administrative_area_level_1')) {
              locResult.state = component.short_name;
            }
            if (
              types.has('locality')
              || types.has('sublocality_level_1')
              || types.has('administrative_area_level_3')) {
              locResult.city = component.short_name;
            }
          }
          if (locResult.country && locResult.state && locResult.city) {
            dispatch(updateLocation(locResult));
          }
        }
      }
    } finally {
      dispatch({ type: ONBOARDING.FETCHING_LOCATION_COMPLETE });
    }
  }
};

export const updateProfileImage = (profile) => async (dispatch) => {
  dispatch({
    type: ONBOARDING.UPDATE_PROFILE,
    profile,
  });
};

export const updateStep = (step) => async (dispatch) => {
  dispatch({
    type: ONBOARDING.UPDATE_STEP,
    step,
  });
};

export const updateUsername = (username) => async (dispatch) => {
  dispatch({
    type: ONBOARDING.UPDATE_USERNAME,
    username,
  });
};

export const updateTitle = (title) => async (dispatch) => {
  dispatch({
    type: ONBOARDING.UPDATE_TITLE,
    title,
  });
};

export const updateCompany = (company) => async (dispatch) => {
  dispatch({
    type: ONBOARDING.UPDATE_COMPANY,
    company,
  });
};

export const updateEmail = (email) => async (dispatch) => {
  dispatch({
    type: ONBOARDING.UPDATE_EMAIL,
    email,
  });
};

export const updateAuthEmail = (email) => async (dispatch) => {
  dispatch({
    type: ONBOARDING.UPDATE_AUTH_EMAIL,
    authEmail: email,
  });
};

export const updateFullName = (fullName) => async (dispatch) => {
  dispatch({
    type: ONBOARDING.UPDATE_FULL_NAME,
    fullName,
  });
};

export const updateTitles = (titles) => async (dispatch) => {
  dispatch({
    type: ONBOARDING.UPDATE_TITLES,
    titles,
  });
};

export const updateIndustries = (industries) => async (dispatch) => {
  dispatch({
    type: ONBOARDING.UPDATE_INDUSTRIES,
    industries,
  });
};

export const updateInterests = (interests) => async (dispatch) => {
  dispatch({
    type: ONBOARDING.UPDATE_INTERESTS,
    interests,
  });
};
