/* eslint-disable no-alert, no-console, linebreak-style */
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React, {
  useState, createContext, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase';
import { useDispatch, useSelector } from 'react-redux';
import {
  approveConnection, rejectConnection,
} from '../../common/data/actions';
import DmChatStore from '../../common/mobx/stores/DmChatStore';
import 'firebase/firestore';
import newApi from '../../common/api/newApi';

const firestore = firebase.firestore();
const resetObj = {
  messages: ['messages'],
  // connection: ['connection_request'],
};

export const InboxConnectionsContext = createContext();
const InboxConnectionsContextProvider = ({ children }) => {
  const user = useSelector((state) => state.account.user) || {};
  const { followersCount } = user;
  const [$connectionStatus, setConnectionStatus] = useState();
  const loadingPosts = useSelector((state) => state.activity.loadingPosts);
  const hasMorePosts = useSelector((state) => state.activity.hasMorePosts);
  const dispatch = useDispatch();
  const pageSize = 10;
  const [supportCount, setSupportCount] = useState(0);
  const [resetObject, setResetObject] = useState(null);

  const [counter = {
    messages: 0,
    notification: 0,
    // connection: 0,
  }, setCounter] = useState();

  useEffect(() => {
    if (!user || !user.id) return () => {};
    const unsubscribe1 = firestore
      .collection('notifications')
      .doc('support')
      .onSnapshot((snapshot) => {
        const content = snapshot.data();
        if (content) {
          const keys = Object.keys(content);
          if (content[keys.length]) {
            setSupportCount(content[keys].length);
          }
        }
      });

    return () => {
      unsubscribe1();
    };
  }, [user]);

  useEffect(() => {
    if (!user || !user.id) return () => {};
    const fetchNotifications = async () => {
      const resp = await newApi
        .get('data/notifications?keys=true')
        .catch((err) => console.log(err));
      // console.log('these are objects: ', resp.data);
      const objectContent = resp.data;
      setResetObject(objectContent);
    };
    fetchNotifications();
    if (resetObject === null) {
      fetchNotifications();
    }
    return () => {
      setResetObject(null);
    };
  }, [user]);

  const countUnreads = (content) => {
    const unread = {
      messages: 0,
      notification: 0,
      // connection: 0,
    };
    const inboxItems = Object.keys(content);

    for (let i = 0; i < inboxItems.length; i += 1) {
      if (resetObj.messages.indexOf(inboxItems[i]) !== -1) {
        const msgsNotificationsCount = Object.values(content[inboxItems[i]]);
        unread.messages = msgsNotificationsCount.reduce((prev, cur) => prev + cur, 0);
      }
      if (resetObject?.indexOf(inboxItems[i]) !== -1) {
        unread.notification += content[inboxItems[i]];
      }
      // if (resetObj.connection.indexOf(inboxItems[i]) !== -1) {
      //   unread.connection += content[inboxItems[i]].length;
      // }
    }
    setCounter(unread);
  };

  useEffect(() => {
    if (!user || !user.id) return () => {};
    const unsubscribe = firestore
      .collection('notifications')
      .doc(user.id)
      .onSnapshot((snapshot) => {
        if (snapshot.empty) {
          return;
        }
        const content = snapshot.data();
        if (content && content.messages) {
          if (!DmChatStore.shouldClearNotification(content.messages)) {
            console.log('calling snapshopt');
            countUnreads(content);
          }
        }
      });
    return () => {
      unsubscribe();
    };
  }, [resetObject]);

  const handleAcceptConnection = async (id) => {
    const action = await dispatch(approveConnection(id));
    const res = action.payload;
    // console.log($connectionStatus, res);
    // const action = dispatch(approveConnection(id));
    // const res = await action.playload;
    if (res) {
      setConnectionStatus('connection_accepted');
      toast.success((await res).message);
    }
  };

  const handleRejectConnection = async (id) => {
    dispatch(rejectConnection(id));
    // const res = await action.playload;
    toast.error('Connection Declined');
    setConnectionStatus('connection_rejected');
    console.log($connectionStatus);
  };
  let allNofications = counter.notification; /* counter.connection */
  const messageCounter = counter.messages;
  if (user.isAdmin) {
    allNofications += supportCount;
  }

  return (
    <>
      <InboxConnectionsContext.Provider value={
      {
        counter,
        pageSize,
        loadingPosts,
        hasMorePosts,
        followersCount,
        handleAcceptConnection,
        handleRejectConnection,
        allNofications,
        messageCounter,
        supportCount,
        // posts,
        // fetchPosts,
        // requestReceivedList,
        // pendingRequestsList,
        // requestCount,
      }
    }
      >
        {children}
      </InboxConnectionsContext.Provider>
    </>
  );
};

InboxConnectionsContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default InboxConnectionsContextProvider;
