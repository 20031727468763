/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import {
  flow, runInAction, makeObservable, observable, action,
} from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import algoliasearch from 'algoliasearch/lite';
// eslint-disable-next-line import/no-cycle
import PubnubInstance from '../shared/PubnubInstance';
import ChatStore from '../shared/ChatStore';
import newApi from '../../api/newApi';
import firebase from '../../../src/firebase';

const firestore = firebase.firestore();

export const clearNotifications = (userId, cId) => {
  firestore.collection('notifications')
    .doc(userId)
    .update({
      [`messages.${cId}`]: firebase.firestore.FieldValue.delete(),
    });
};

const structConversation = (msgEvent, userId) => {
  const convoStruct = {
    id: msgEvent.channel,
    updated: Math.floor(msgEvent.timetoken / 1e4),
  };

  switch (msgEvent.message.type) {
    case 'text':
      convoStruct.lastMessage = msgEvent.message.msgText;
      break;
    case 'attachment': {
      if (msgEvent.message.senderId === userId) {
        convoStruct.lastMessage = 'You sent an attachment';
      } else {
        convoStruct.lastMessage = 'Sent you an attachment';
      }
      break;
    }
    default:
      console.log('UNHANDLED MESSAGE TYPE');
      break;
  }

  return convoStruct;
};

const hitsToConversations = (hits) => hits.map((h) => ({
  id: h.objectID,
  participantsInfo: h.participantsInfo,
  lastMessage: h.message,
  participants: h.participants,
  updated: h.date,
}));

class DmChatStoreClass extends ChatStore {
  constructor() {
    super('DM');
    // Should only be used inside this class, change to private field when that
    // comes to Javascript
    this.idsFetching = [];
    this.searchClient = null;
    this.searchUserClient = null;
    // End
    this.conversations = [];
    this.showQuickChatView = false;
    // this.activeChannelId = null;
    this.prevLoadChatId = null;
    this.msgNotifications = {};
    this.query = '';
    this.hasMoreConversations = false;
    this.nextPage = 0;
    this.loadingConversations = true;
    this.deletingChat = false;
    this.creatingChat = false;
    this.isRoomDmTab = false;
    this.subscribedChannels = {};
    this.prevNotificationCounts = {};

    makeObservable(this, {
      conversations: observable,
      showQuickChatView: observable,
      prevLoadChatId: observable,
      msgNotifications: observable,
      query: observable,
      hasMoreConversations: observable,
      nextPage: observable,
      loadingConversations: observable,
      deletingChat: observable,
      // activeChannelId: observable,
      creatingChat: observable,

      resetChatStore: action,
      setPrevLoadChatId: action,
      setShowQuickChatView: action,
      toggleShowQuickChat: action,
      shouldClearNotification: action,
      setIsRoomDmTab: action,
      updateConversations: action,

      deleteChat: flow,
      handleSubscribeToChannels: flow,
      initSetup: flow,
      loadMoreConversations: flow,
      updateQuery: flow,
      loadChatRoom: flow,
      // sendMessage: flow,
      deleteConversation: flow,
      createChat: flow,
    });
  }

  /**
   * These methods shouldn't be called outside this class
   * Only mutatates the state(observables)
   */

  updateConversations(newConversation, channelDetails) {
    this.conversations = [
      {
        ...channelDetails,
        ...newConversation,
        participantsInfo: Object.values(channelDetails.participantsInfo),
      },
      ...this.conversations.filter((convo) => convo.id !== newConversation.id),
    ];
  }

  updateFetchedConversations(response) {
    if (response && response.hits) {
      this.hasMoreConversations = response.nbPages - 1 > response.page;
      this.nextPage = response.page + 1;

      const fetchedConversations = hitsToConversations(response.hits.filter((r) => !r.escape));
      if (response.page === 0) {
        this.conversations = fetchedConversations;
      } else {
        this.conversations = [
          ...this.conversations,
          ...fetchedConversations,
        ];
      }
    } else {
      this.hasMoreConversations = false;
      this.nextPage = 0;
    }
  }

  async fetchChannelDetails(channelId) {
    let res = {};
    try {
      res = await newApi.get(`chat/${channelId}`);
      this.searchClient.clearCache();
    } catch (error) {
      console.log(error);
    }
    return res;
  }

  * fetchChat() {
    PubnubInstance.initPubnubInstance();

    yield super.fetchChat();
    if (!(this.activeChannelId in this.subscribedChannels)) {
      this.subscribeToChannels([this.activeChannelId]);
    }
  }

  subscribeToChannels(channels = []) {
    PubnubInstance.subscribeToChannels(channels);
    channels.forEach((channel) => {
      this.subscribedChannels[channel] = true;
    });
  }

  unsubscribeChannels(channels = []) {
    PubnubInstance.unsubscribeFromChannels(channels);
    channels.forEach((channel) => {
      delete this.subscribedChannels[channel];
    });
  }

  async getLatestChannelDetails(chatId) {
    // If chat is already subscribed to, then it receives update when someone
    // leaves or join the chat hence no need to fetch latest one again
    let channelDetails = null;
    const foundDetails = this.conversations.find((ch) => ch.id === chatId);

    if (chatId in this.subscribedChannels
    && foundDetails) {
      // const foundDetails = this.conversations.find((ch) => ch.id === chatId);

      channelDetails = {
        participants: foundDetails.participants,
        participantsInfo: foundDetails.participantsInfo
          .reduce((prev, cur) => ({ ...prev, [cur.id]: cur }), {}),
      };
    } else {
      const res = await this.fetchChannelDetails(chatId);

      if (res?.data.participantsInfo) {
        const { participantsInfo, participants } = res.data;
        channelDetails = {
          participants,
          participantsInfo: foundDetails ? foundDetails.participantsInfo
            .reduce((prev, cur) => ({ ...prev, [cur.id]: cur }), {}) : participantsInfo.reduce(
            (prev, cur) => ({ ...prev, [cur.id]: cur }),
            {},
          ),
        };
      }
    }

    return channelDetails;
  }

  * handleSubscribeToChannels(channelIds) {
    PubnubInstance.initPubnubInstance();

    let fetchedMessages = yield PubnubInstance.fetchMessages(channelIds, 1);
    if (fetchedMessages) {
      fetchedMessages = Object.values(fetchedMessages);
      fetchedMessages
        .sort((chat1, chat2) => chat1[0].timetoken - chat2[0].timetoken);
      fetchedMessages.forEach((chat) => {
        runInAction(async () => {
          const channelId = chat[0].channel;
          const channelDetails = await this.getLatestChannelDetails(channelId);
          if (!channelDetails) {
            return;
          }

          const newConversation = structConversation(chat[0], PubnubInstance.user.id);
          this.updateConversations(newConversation, channelDetails);
          this.subscribeToChannels([channelId]);
        });
      });
    }
  }

  /// //
  /// //
  /// //
  /** End */

  /// //
  /// //
  /** Methods called outside this class */

  shouldClearNotification(newMsgsNotication) {
    const isInCallroom = window.location.pathname.split('/').includes('room');
    if (isInCallroom && this.isRoomDmTab && (this.activeChannelId in newMsgsNotication)) {
      return true;
    }
    return (!isInCallroom && (this.activeChannelId in newMsgsNotication));
  }

  setIsRoomDmTab(value) {
    this.isRoomDmTab = value;
  }

  * initSetup() {
    if (!PubnubInstance.user) return;
    /** Connect to Algolia and fetch convresations
     *  Subscribe to channels in the channel group to receive new messages events
    */

    try {
      const res = yield newApi.get('chat/conversation/search-token');
      if (res && res.data) {
        const client = algoliasearch(process.env.ALGOLIA_APP_ID, res.data.key);
        this.searchClient = client.initIndex('conversations');
        this.searchClient.clearCache = client.clearCache;
        this.loadMoreConversations(true);
      }

      const res1 = yield newApi.get('user/connections-search-token');
      if (res1 && res1.data) {
        const client = algoliasearch(process.env.ALGOLIA_APP_ID, res1.data.key);
        this.searchUserClient = client.initIndex('users');
      }
    } catch (error) {
      // console.log('channelList Error', error);
    }

    let firstFetch = true;

    const unsubscribeNotificatons = firestore
      .collection('notifications')
      .doc(PubnubInstance.user.id)
      .onSnapshot((snapshot) => {
        const content = snapshot.data();

        if (content) {
          runInAction(() => {
            if (content && content.messages) {
              // Checks and get new channel ids to subscribe to
              let newlyReceivedIds = [];
              if (!firstFetch) {
                newlyReceivedIds = Object
                  .keys(content.messages || {}).filter((val) => {
                    if (val in this.subscribedChannels) {
                      return false;
                    }
                    if (val in this.prevNotificationCounts) {
                      return this.prevNotificationCounts[val] !== content.messages[val];
                    }
                    return true;
                  });
              }
              Object.keys(content.messages || {}).forEach((channel) => {
                this.prevNotificationCounts[channel] = content.messages[channel];
              });
              // Subscribes to channels with new messages(i.e notification count changed)
              this.handleSubscribeToChannels(newlyReceivedIds);

              if (this.shouldClearNotification(content.messages)) {
                clearNotifications(PubnubInstance.user.id, this.activeChannelId);
              } else {
                this.msgNotifications = content.messages || {};
              }

              firstFetch = false;
            }
          });
        }
      });

    // Should be called to cancel subscriptions
    this.unsubscribeChatSubscriptions = () => {
      if (PubnubInstance.pubnub) {
        PubnubInstance.pubnub.unsubscribeAll();
      }
      unsubscribeNotificatons();
    };
  }

  // connected to a function above
  // eslint-disable-next-line class-methods-use-this
  unsubscribeChatSubscriptions() {}

  // Called in App.js in the event listener
  * handleMessageEvent({ detail: { msgEvent } }) {
    const { channel, message } = msgEvent;
    const { type, senderId } = message;

    super.handleMessageEvent(msgEvent);
    if (type === 'connect') {
      // To know if to enable or disable input field when users leave the chat
      let newParticipantsIds;

      if (message.connectType === 'joined') {
        newParticipantsIds = [
          ...this.activeChannelInfo.participants,
          senderId,
        ];
      } else {
        newParticipantsIds = this.activeChannelInfo
          .participants.filter((val) => val !== senderId);
      }

      // Need to update the corresponding conversation's participants list
      const convIndex = this.conversations.findIndex((conv) => conv.id === channel);
      if (convIndex !== -1) {
        this.conversations = [
          ...this.conversations.slice(0, convIndex),
          {
            ...this.conversations[convIndex],
            participants: this
              .conversations[convIndex].participants.filter((val) => val !== senderId),
          },
          ...this.conversations.slice(convIndex + 1),
        ];
      }

      if (channel === this.activeChannelId) {
        this.setActiveChannelParticipaantsIds(newParticipantsIds);
      }
    } else {
      const channelDetails = yield this.getLatestChannelDetails(channel);
      if (!channelDetails) {
        if (message.senderId === PubnubInstance.user.id) {
          toast.error('Problem sending message, please try again!',
            { hideProgressBar: true, closeOnClick: true, pauseOnHover: true });
        }
        return;
      }

      const newConversation = structConversation(msgEvent, PubnubInstance.user.id);
      this.updateConversations(newConversation, channelDetails);
    }

    yield this.searchClient.clearCache();
  }

  * loadMoreConversations(initFetch = false) {
    if (!initFetch && (this.loadingConversations || !this.searchClient)) return;

    if (initFetch) {
      yield this.searchClient.clearCache();
    }
    this.loadingConversations = true;
    const response = yield this.searchClient.search(this.query, {
      page: this.nextPage,
    });
    this.updateFetchedConversations(response);
    this.loadingConversations = false;
  }

  * createChat(_, chatUsers = []) {
    this.clearChat();
    this.creatingChat = true;
    const prevChatId = this.activeChannelId;
    this.activeChannelId = null;

    const participantsInfo = [...chatUsers, PubnubInstance.user];
    const participantsIds = participantsInfo.map((chUser) => chUser.id);

    this.setActiveChannelInfo({
      participants: participantsIds,
      participantsInfo: participantsInfo
        .reduce((prev, cur) => ({ ...prev, [cur.id]: cur }), {}),
    });

    yield newApi
      .post('chat/create/v2', { ids: chatUsers.map((us) => us.id) })
      .then((response) => {
        if (!response.data.channel_id) {
          throw new Error();
        }

        const { participants } = response.data;
        if (participants) { // Chat has been created before, so we need to sync
          this.setActiveChannelParticipaantsIds(participants);
        }

        const channelId = response.data.channel_id;
        this.activeChannelId = channelId;
        this.fetchChat();

        if (!(channelId in this.subscribedChannels)) {
          this.subscribeToChannels([channelId]);
        }
      })
      .catch(() => {
        this.loadChatRoom(prevChatId);
        toast.error('Problem creating chat, please try again!.', { hideProgressBar: true, pauseOnHover: true });
      });

    this.creatingChat = false;
  }

  * loadChatRoom(cId) {
    if (this.activeChannelId === cId || this.loadingChat) return false;
    this.activeChannelId = cId;

    this.loadChatRoomInit();

    const channelDetails = yield this.getLatestChannelDetails(cId);
    this.loadingChat = false; // needed to make call to super.fetchChat() pass

    if (!channelDetails) {
      this.activeChannelId = null;
      return toast.error('Problem loading chat!',
        { hideProgressBar: true, closeOnClick: true, pauseOnHover: true });
    }

    this.setActiveChannelInfo(channelDetails);

    if (this.msgNotifications && cId in this.msgNotifications) {
      clearNotifications(PubnubInstance.user.id, cId);
    }

    yield this.fetchChat();
    return false;
  }

  * deleteChat(chatId) {
    const isInboxTab = window.location.pathname.split('/').includes('messaging');
    if (isInboxTab) {
      let newChannelId;
      this.unsubscribeChannels([chatId]);
      if (this.conversations[0].id === chatId) {
        newChannelId = this.conversations[1] ? this.conversations[1].id : '';
      } else {
        newChannelId = this.conversations[0].id;
      }

      if (this.activeChannelId === chatId) {
        this.activeChannelId = newChannelId;
      }

      this.clearChat();

      const channelDetails = yield this.getLatestChannelDetails(this.activeChannelId);

      if (channelDetails) {
        this.setActiveChannelInfo(channelDetails);
      }

      this.fetchChat();
    }

    this.conversations = this.conversations.filter((convo) => convo.id !== chatId);

    if (this.showQuickChatView && !isInboxTab && this.activeChannelId === chatId) {
      if (this.showQuickChatView) {
        this.resetChatStore(true);
      }
      this.activeChannelId = null;
    }
  }

  * deleteConversation(channel) {
    try {
      this.deletingChat = true;
      yield newApi.delete(`chat/conversation/${channel}`);

      yield this.deleteChat(channel);
    } catch (err) {
      toast.error('Problem deleting chat, please try again',
        { hideProgressBar: true, closeOnClick: true, pauseOnHover: true });
    } finally {
      yield this.searchClient.clearCache();
      this.deletingChat = false;
    }
  }

  * sendMessage(msgQuery) {
    const { user } = PubnubInstance;
    this.removeChannelMsgQuery(this.activeChannelId);
    const newMsg = {
      type: 'text',
      msgText: msgQuery.trim(),
      id: uuidv4(),
      senderId: user.id,
    };

    if (this.shouldClearNotification(this.msgNotifications)) {
      clearNotifications(user.id, this.activeChannelId);
    }

    const mockMsg = {
      id: uuidv4(),
      senderId: user.id,
      fullName: user.fullName,
      userName: user.username,
      messages: [
        {
          text: newMsg.msgText,
          id: newMsg.id,
          failed: false,
          type: 'text',
        },
      ],
      time: Math.floor(new Date().getTime()),
    };
    this.addMockMessage(mockMsg);

    const published = yield PubnubInstance.publish(newMsg, this.activeChannelId);
    if (!published) {
      this.addMessageFailFlag(newMsg.id);
      toast.error('Problem sending message, please check your internet connection or refresh your browser.', { hideProgressBar: true, pauseOnHover: true });
    }
    yield this.searchClient.clearCache();
  }

  * resendMessage(msgObject) {
    this.removeMockMessage(msgObject.id);
    yield this.sendMessage(msgObject.text);
  }

  * updateQuery(q) {
    if (this?.searchClient) {
      this.query = q;
      const response = yield this.searchClient.search(q);
      this.updateFetchedConversations(response);
    }
  }

  resetChatStore(closeQuickChat = false) {
    this.chat = [];
    this.lastMessage = null;
    this.activeChannelId = null;

    if (closeQuickChat) {
      this.showQuickChatView = false;
    }
  }

  toggleShowQuickChat() {
    this.showQuickChatView = !this.showQuickChatView;
  }

  setShowQuickChatView(val) {
    this.showQuickChatView = val;
  }

  setPrevLoadChatId(val) {
    this.prevLoadChatId = val;
  }
}

const DmChatStore = new DmChatStoreClass();

export default DmChatStore;
