import { handleActions } from 'redux-actions';
import {
  updateEthState,
} from '../actions/ethActions';

const INITIAL_STATE = {
  userEthbalance: null,
  weiBalance: 0,
  price: 0,
};

const reducer = handleActions(
  {
    [updateEthState]: (state, { payload }) => ({ ...state, ...payload }),
  },
  INITIAL_STATE,
);

export default reducer;
