import { handleActions } from 'redux-actions';
import {
  loadDesoAppStateData,
  loadDesoAppStateDataComplete,
  updateDesoUserState,
  desoProfileComplete,
  desoHasPhoneVerified,
  loadProfileData,
  loadProfileDataComplete,
  setDesoFilter,
  loadDesoFeedComplete,
  resetDesoPosts,
  loadGlobalFeed,
  loadHotFeed,
  updateUserMetadata,
} from '../actions/desoActions';

const INITIAL_STATE = {
  feed: [],
  loadingFeed: true,
  hasMoreFeed: false,
  loading: false,
  USDCentsPerDeSoExchangeRate: null,
  diamondLevelMap: null,
  profileCompleted: false,
  phoneNumber: false,
  balanceNanos: 0,
  price: 0,
  isBlackListed: false,
  isGrayListed: false,
  currentDesoFilter: 'global',
};

const reducer = handleActions(
  {
    [setDesoFilter]: (state, { payload }) => ({ ...state, currentDesoFilter: payload }),
    [loadGlobalFeed]: (state) => ({ ...state, loadingFeed: true }),
    [loadHotFeed]: (state) => ({ ...state, loadingFeed: true }),
    [resetDesoPosts]: (state) => ({ ...state, feed: INITIAL_STATE.feed }),
    [updateDesoUserState]: (state, { payload }) => ({ ...state, ...payload }),
    [loadDesoAppStateData]: (state) => ({ ...state, loading: true }),
    [loadDesoAppStateDataComplete]: (state, { payload }) => ({
      ...state,
      USDCentsPerDeSoExchangeRate: payload.USDCentsPerDeSoExchangeRate,
      diamondLevelMap: payload.DiamondLevelMap,
      loading: false,
    }),
    [desoProfileComplete]: (state) => ({ ...state, profileCompleted: true }),
    [desoHasPhoneVerified]: (state) => ({ ...state, phoneNumber: true }),
    [loadProfileData]: (state) => ({ ...state, loading: true }),
    [loadProfileDataComplete]: (state, { payload }) => {
      const desoExchangeRate = state.USDCentsPerDeSoExchangeRate;
      const balanceNanos = parseFloat((payload.BalanceNanos / 1000000000).toFixed(5));
      const isProfileCompleted = !!payload.ProfileEntryResponse;
      return {
        ...state,
        isBlackListed: payload.IsBlacklisted,
        isGrayListed: payload.IsGraylisted,
        balanceNanos,
        price: ((balanceNanos * desoExchangeRate) / 100).toFixed(2),
        loading: false,
        phoneNumber: payload.HasPhoneNumber,
        profileCompleted: isProfileCompleted,
      };
    },
    [loadDesoFeedComplete]: (state, { payload }) => {
      let feed = payload;
      if (payload.length && state.feed.length > 0) {
        const currentId = state.feed.map((e) => e.PostHashHex);
        const filterPayload = payload.filter((e) => !currentId.includes(e.PostHashHex));
        feed = [...state.feed, ...filterPayload];
      } else if (payload.length === 0 && state.feed.length > 0) {
        feed = state.feed;
      }
      return {
        ...state,
        feed,
        hasMoreFeed: payload.length > 1,
        loadingFeed: false,
      };
    },
    [updateUserMetadata]: (state, { payload }) => ({
      ...state,
      phoneNumber: payload.HasPhoneNumber,
    }),
  },
  INITIAL_STATE,
);

export default reducer;
