import { createAction } from 'redux-actions';
import { getStore } from '../../../store';
import newApi from '../../api/newApi';
import desoApi from '../../api/deso';

export const setFilter = createAction('SET_FEED_FILTER');
export const setCurrentSearch = createAction('SET_SEARCH_QUERY_HOME');
export const setCurrentFeedPage = createAction('SET_FILTER_PAGE');
export const loadFeedComplete = createAction('LOAD_FEED_SUCCESS');
export const resetFeed = createAction('RESET_FEED', () => {
  newApi.cancelAll();
});
export const loadFeed = createAction('LOAD_FEED', async (limit = 10, offset = 0, filter = null) => {
  const { auth, account, deso } = getStore().getState();
  let url = `post?limit=${limit}&offset=${offset}`;
  if (filter && filter.feedFilter === 'pitches') {
    url = `pitch?limit=${limit}&offset=${offset}`;
  }
  if (!auth.token) {
    url = `public/${url}`;
  }
  if (filter) {
    if (filter.industry && filter.industry !== 'All') {
      url += `&industry=${filter.industry}`;
    }
    if (filter.location && filter.location !== 'All') {
      url += `&city=${filter.location.city}`;
    }
    if (filter.feedFilter && filter.feedFilter === 'following') {
      url += '&connectedUsers=true';
    } else if (filter.feedFilter) {
      url += `&sortBy=${filter.feedFilter}`;
    }
    // if (filter.searchQuery && filter.searchQuery !== '') {
    //   url += `&searchText=${filter.searchQuery}`;
    // }
  }
  const resp = await newApi.get(url, 'feed', true);
  if (resp && resp.data && Array.isArray(resp.data)) {
    const promises = resp.data.map(async (p) => {
      const { USDCentsPerDeSoExchangeRate } = deso;
      if (p.DesoPostHashHex) {
        const payload = {
          PostHashHex: p.DesoPostHashHex,
          CommentLimit: 10,
        };
        if (account.user.deso) {
          payload.ReaderPublicKeyBase58Check = account.user.deso;
        }
        try {
          const desoData = await desoApi.post('get-single-post', payload);
          if (desoData.data && desoData.data.PostFound) {
            const { ProfileEntryResponse } = desoData.data.PostFound;
            const { CoinPriceDeSoNanos } = ProfileEntryResponse;
            const balance = (CoinPriceDeSoNanos / 1000000000).toFixed(2);
            const price = ((balance * USDCentsPerDeSoExchangeRate) / 100).toFixed(2);
            const author = { ...p.author, coinPrice: price };
            return { ...p, author, deso: desoData.data.PostFound };
          }
        } catch (err) {
          // do nothing
        }
      }
      return p;
    });
    const data = await Promise.all(promises);
    getStore().dispatch(loadFeedComplete(data));
  } else {
    // dispatch(loadFeedFailed(resp.data.error));
  }
});

export const loadPostsComplete = createAction('LOAD_POSTS_SUCCESS');
export const resetPosts = createAction('RESET_POSTS');
export const loadPosts = createAction('LOAD_POSTS', async (limit = 10, offset = 0, filter = null) => {
  let url = `post?limit=${limit}&offset=${offset}`;
  if (filter) {
    if (filter.industry && filter.industry !== 'All') {
      url += `&industry=${filter.industry}`;
    }
    if (filter.location && filter.location !== 'All') {
      url += `&city=${filter.location.city}`;
    }
    if (filter.searchQuery && filter.searchQuery !== '') {
      url += `&searchText=${filter.searchQuery}`;
    }
    if (filter.contentFilter && filter.contentFilter !== '') {
      url += `&sortBy=${filter.contentFilter}`;
    }
  }
  const resp = await newApi.get(url);
  if (resp && resp.data) {
    getStore().dispatch(loadPostsComplete(resp.data));
  } else {
    // dispatch(loadPostsFailed(resp.data.error));
  }
});

export const setPostTipComplete = createAction('SET_POST_TIP_COMPLETE');
export const setPostTip = createAction('SET_POST_TIP', async (postId, amount) => {
  if (postId && amount) {
    getStore().dispatch(setPostTipComplete({ postId, amount }));
  }
});
export const setVoteComplete = createAction('SET_VOTE_SUCCESS');
export const setVote = createAction('SET_VOTE', async (postId, isFavorite) => {
  const resp = await newApi.post(`post/${postId}/upvote`, {
    isFavorite,
  });
  if (resp && resp.data) {
    getStore().dispatch(setVoteComplete({ postId, isFavorite }));
  }
});

export const blockUserComplete = createAction('BLOCK_USER_SUCCESS');
export const blockUser = createAction('BLOCK_USER', async (userId, callback = null) => {
  window.analytics.track('User Block', { id: userId });
  const resp = await newApi.post('user/block', {
    blockUserId: userId,
  });
  if (resp && resp.data.data) {
    getStore().dispatch(blockUserComplete(userId));
    if (callback) {
      callback(resp.data.chatId);
    }
  } else {
    // dispatch(loadPostFailed(resp.data.error));
  }
});

export const deletePostComplete = createAction('DELETE_POST_SUCCESS');
export const deletePost = createAction('DELETE_POST', async (postId) => {
  getStore().dispatch(deletePostComplete(postId));
  await newApi.delete(`post/${postId}`);
});

export const deleteCommentComplete = createAction('DELETE_COMMENT_SUCCESS');
export const addCommentComplete = createAction('ADD_COMMENT_SUCCESS');
export const addCommentFailed = createAction('ADD_COMMENT_FAILED');
