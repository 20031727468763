// /* eslint-disable max-classes-per-file */
import {
  action, flow, makeObservable, observable,
} from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PubnubInstance from './PubnubInstance';

const structMessage = (message, senderInfo) => {
  const msgStruct = {
    senderId: message.message.senderId,
    fullName: senderInfo?.fullName,
    userAvatar: senderInfo?.avatar,
    userName: senderInfo?.username,
    isPro: senderInfo?.isPro,
    type: message.message.type,
    messages: [
      {
        id: message.message.id,
        type: message.message.type,
      },
    ],
    time: message.timetoken / 1e4,
    id: uuidv4(),
  };

  switch (message.message.type) {
    case 'text':
      msgStruct.messages[0].text = message.message.msgText;
      break;
    case 'attachment':
      msgStruct.messages[0].attachment = message.message.attachment;
      break;
    case 'connect':
      msgStruct.messages[0].text = `${senderInfo.fullName} ${message.message.connectType} the chat`;
      msgStruct.messages[0].connectType = message.message.connectType;
      break;
    default:
      console.log('UNHANDLED MESSAGE TYPE');
      // Backward compatibility, very old messages don't have types and were only text
      if (message.message.msgText) {
        msgStruct.messages[0].text = message.message.msgText;
        msgStruct.messages[0].type = 'text';
      }
      break;
  }

  return msgStruct;
};

class ChatStore {
  constructor(storeId) {
    this.messageCount = 20;
    this.chat = [];
    this.isOldMessageFetched = false;
    this.lastMessage = null;
    this.hasMoreMessages = false;
    this.startTimeToken = '';
    this.fetchingMoreMsgs = false;
    this.loadingChat = false;
    this.mockMessages = null;
    this.storeId = storeId;
    this.activeChannelId = null;
    this.msgsCache = {};
    this.chatMsgsSenderDetails = {};
    this.msgQueries = {};

    /**
     * Controlled in child class
     */
    // this.activeChannelId = null;
    this.activeChannelInfo = {};

    makeObservable(this, {
      fetchMessages: flow,
      fetchChat: flow,
      fetchMoreMessages: flow,
      handleMessageEvent: flow,
      sendMessage: flow,
      resendMessage: flow,
      fetchMsgSenderDetails: flow,
      setActiveChannelInfo: action,
      setActiveChannelParticipaantsIds: action,
      updateMsgQuery: action,
      loadChatRoomInit: action,

      activeChannelId: observable,
      chat: observable,
      isOldMessageFetched: observable,
      hasMoreMessages: observable,
      fetchingMoreMsgs: observable,
      loadingChat: observable,
      mockMessages: observable,
      activeChannelInfo: observable,
      chatMsgsSenderDetails: observable,
      msgQueries: observable,
      storeId: observable,
    });
  }

  addMockMessage(mockMsg) {
    if (this.mockMessages) {
      this.mockMessages = {
        ...this.mockMessages,
        messages: [
          ...this.mockMessages.messages,
          ...mockMsg.messages,
        ],
      };

      return;
    }

    this.mockMessages = mockMsg;
  }

  addMessageFailFlag(messageId) {
    this.mockMessages = {
      ...this.mockMessages,
      messages: this.mockMessages.messages.map((msg) => (msg.id === messageId ? {
        ...msg,
        failed: true,
      } : msg)),
    };
  }

  removeMockMessage(messageId) {
    const newMockMsgs = {
      ...this.mockMessages,
      messages: this.mockMessages?.messages?.filter((msg) => msg.id !== messageId),
    };

    this.mockMessages = newMockMsgs?.messages?.length ? newMockMsgs : null;
  }

  addChatMessages(messages) {
    let stateUpdate = {
      lastMessage: null,
      chat: [],
    };

    messages.filter((msg) => msg.message?.type !== 'action').forEach((msg) => {
      const senderInfo = this.activeChannelInfo.participantsInfo[msg.message.senderId];

      const structMsg = structMessage(msg, senderInfo);

      // connect type messages should stand alone as a message group so we can render it alone
      const shouldAddToMsgGroup = structMsg.type !== 'connect';

      if (shouldAddToMsgGroup
        && stateUpdate.lastMessage && stateUpdate.lastMessage.senderId === structMsg.senderId) {
        // Updates previous messages sent by current user with newly recieved data
        const lastMessage = {
          ...stateUpdate.lastMessage,
          messages: [
            ...stateUpdate.lastMessage.messages,
            ...structMsg.messages,
          ],
          time: structMsg.time,
        };

        const chatLasMessageUpdate = {
          ...stateUpdate.chat[stateUpdate.chat.length - 1],
          messages: [
            ...stateUpdate.chat[stateUpdate.chat.length - 1].messages,
            ...structMsg.messages,
          ],
          time: structMsg.time,
        };

        stateUpdate = {
          ...stateUpdate,
          lastMessage,
        };
        stateUpdate.chat[stateUpdate.chat.length - 1] = chatLasMessageUpdate;
      } else {
        stateUpdate = {
          ...stateUpdate,
          lastMessage: shouldAddToMsgGroup ? structMsg : null,
          chat: [
            ...stateUpdate.chat,
            structMsg,
          ],
        };
      }
    });

    // To update previously loaded chat if any and if the last message on
    // stateUpdate list nor the first message on chat list is of type 'connect'
    const shouldMergeMsgGroup = !(stateUpdate.chat[stateUpdate.chat.length - 1].type === 'connect' || this.chat[0]?.type === 'connect')
      && stateUpdate.chat[stateUpdate.chat.length - 1].senderId === this.chat[0]?.senderId;
    if (shouldMergeMsgGroup) {
      const newLastMessage = stateUpdate.chat[stateUpdate.chat.length - 1];
      const newFirstMessage = this.chat[0];

      this.chat = [
        ...stateUpdate.chat.slice(0, -1),
        {
          ...newFirstMessage,
          messages: [...newLastMessage.messages, ...newFirstMessage.messages],
        },
        ...this.chat.slice(1),
      ];
      return;
    }

    this.lastMessage = this.chat.length ? this.lastMessage : stateUpdate.lastMessage;
    this.chat = [...stateUpdate.chat, ...this.chat];
  }

  addNewChatMessage(message) {
    const fetchedMsgsIds = new Set(this.chat?.map((ch) => ch.messages.map((msg) => msg.id)).flat());
    if (this.activeChannelId !== message.channel
      || fetchedMsgsIds.has(message.message.id)) return;

    const senderInfo = this.activeChannelInfo.participantsInfo[message.message.senderId];

    const newMsg = structMessage(message, senderInfo);

    // connect type messages should stand alone as a message group so we can render it alone
    const shouldAddToMsgGroup = newMsg.type !== 'connect';
    if (shouldAddToMsgGroup && this.lastMessage && this.lastMessage.senderId === newMsg.senderId) {
      // Updates previous messages sent by current user with newly recieved data
      const lastMessage = {
        ...this.lastMessage,
        messages: [
          ...this.lastMessage.messages,
          ...newMsg.messages,
        ],
        time: newMsg.time,
      };

      this.lastMessage = lastMessage;
      this.chat = [
        ...this.chat.slice(0, this.chat.length - 1),
        {
          ...this.chat[this.chat.length - 1],
          messages: [
            ...this.chat[this.chat.length - 1].messages,
            ...newMsg.messages,
          ],
          time: newMsg.time,
        },
      ];
      return;
    }

    this.lastMessage = shouldAddToMsgGroup ? newMsg : null;
    this.chat = [
      ...this.chat,
      newMsg,
    ];
  }

  * fetchMessages(channel, messageCount = this.messageCount, startTToken = '') {
    let messagesRes = yield PubnubInstance.fetchMessages([channel], messageCount, startTToken);
    messagesRes = messagesRes[channel];
    if (messagesRes) {
      this.startTimeToken = messagesRes[0].timetoken;
      this.hasMoreMessages = messagesRes.length === messageCount;

      if (channel in this.msgsCache) {
        this.msgsCache[channel] = [
          ...messagesRes,
          ...this.msgsCache[channel],
        ];
      } else {
        this.msgsCache[channel] = messagesRes;
      }

      return messagesRes;
    }
    return undefined;
  }

  clearChat() {
    this.chat = [];
    this.lastMessage = null;
    this.startTimeToken = '';
    this.isOldMessageFetched = false;
    this.mockMessages = null;
  }

  loadChatRoomInit() {
    this.loadingChat = true;
    this.clearChat();
    this.setActiveChannelInfo({});
  }

  * fetchChat() {
    let fetched = false;
    try {
      if (this.loadingChat) return false;
      this.loadingChat = true;
      this.clearChat();

      if (!this.activeChannelId || !PubnubInstance.user) {
        this.loadingChat = false;
        return false;
      }

      let messages;
      if (this.activeChannelId in this.msgsCache) {
        messages = this.msgsCache[this.activeChannelId];
        this.addChatMessages(messages);
        this.startTimeToken = messages[0].timetoken;
      } else {
        messages = yield this.fetchMessages(this.activeChannelId);
        if (messages) {
          this.addChatMessages(messages);
        }
      }

      fetched = !!messages?.length;
    } catch (err) {
      this.activeChannelId = null;
      toast.error('Problem loading chat, please try again!.', { hideProgressBar: true, pauseOnHover: true });
    } finally {
      this.loadingChat = false;
    }
    return fetched;
  }

  * fetchMoreMessages() {
    if (this.fetchingMoreMsgs) return;
    this.fetchingMoreMsgs = true;
    const messages = yield this.fetchMessages(
      this.activeChannelId,
      this.messageCount,
      this.startTimeToken,
    );

    if (messages) {
      this.isOldMessageFetched = true;
      this.addChatMessages(messages);
    }
    this.fetchingMoreMsgs = false;
  }

  * sendMessage(msgObject) {
    const { user } = PubnubInstance;
    const mockMsg = {
      id: uuidv4(),
      senderId: user.id,
      fullName: user.fullName,
      userName: user.username,
      isPro: user.isPro,
      messages: [
        {
          text: msgObject.msgText,
          id: msgObject.id,
          failed: false,
          type: 'text',
        },
      ],
      time: Math.floor(new Date().getTime()),
    };
    this.addMockMessage(mockMsg);

    const published = yield PubnubInstance.publish(msgObject, this.activeChannelId);
    if (!published) {
      this.addMessageFailFlag(msgObject.id);
      toast.error('Problem sending message, please check your internet connection or refresh your browser.', { hideProgressBar: true, pauseOnHover: true });
    }
  }

  * resendMessage(msgObject) {
    this.removeMockMessage(msgObject.id);
    yield this.sendMessage(msgObject);
  }

  setActiveChannelInfo(newChannelInfo) {
    this.activeChannelInfo = newChannelInfo;
  }

  setActiveChannelParticipaantsIds(participantsIds) {
    this.activeChannelInfo = {
      ...this.activeChannelInfo,
      participants: participantsIds,
    };
  }

  // eslint-disable-next-line require-yield
  * handleMessageEvent(msgEvent) {
    this.isOldMessageFetched = false;
    this.removeMockMessage(msgEvent.message.id);
    this.addNewChatMessage(msgEvent);

    /**
     * New mesasges should be added to the cache after updating the UI!
     */
    if (msgEvent.channel in this.msgsCache) {
      this.msgsCache[msgEvent.channel] = [
        ...this.msgsCache[msgEvent.channel],
        msgEvent,
      ];
    }
  }

  setIsOldMessageFetched(val) {
    this.isOldMessageFetched = val;
  }

  removeChannelMsgQuery(channelId) {
    delete this.msgQueries[channelId];
  }

  updateMsgQuery(val, channelId) {
    this.msgQueries[channelId] = val;
  }

  * fetchMsgSenderDetails(sendersId) {
    if (!this.searchUserClient) return;
    const filtered = sendersId.filter((id) => !this.idsFetching.includes(id));

    if (filtered.length > 0) {
      this.idsFetching = [...this.idsFetching, ...filtered];
      const res = yield this.searchUserClient.search('', {
        facetFilters: [sendersId.map((v) => `objectID:${v}`)],
      });

      const newData = res.hits.reduce((prevValue, u) => {
        const val = prevValue;
        val[u.id] = {
          username: u.username,
          fullName: u.fullName,
          avatar: u.avatar,
        };
        return prevValue;
      }, {});
      this.idsFetching = [...this.idsFetching].filter((e) => !filtered.includes(e));
      this.chatMsgsSenderDetails = { ...this.chatMsgsSenderDetails, ...newData };
    }
  }
}

export default ChatStore;
