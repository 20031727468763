/* eslint-disable no-restricted-syntax */
/* eslint-disable no-return-assign, no-param-reassign, no-sequences */
import { handleActions } from 'redux-actions';
import { getRole, getRoleWeight } from '../../../src/components/connect/utils';
import {
  setSession,
  updateRoomState,
  addCameraMap,
  removeCameraMap,
  removeContentMap,
  resetRoomState,
  updateParticipants,
  attendeeLeft,
  attendeeJoined,
  volumeChanged,
} from '../actions/roomActions';

const INITIAL_STATE = {
  session: null,
  attendees: [],
  topic: '',
  roomId: null,
  currentUser: {},
  minimized: false,
  isMuted: true,
  joinUnmuted: false,
  joinWithCameraOn: true,
  canUnmute: false,
  isJoining: false,
  isLeaving: false,
  role: 'audience',
  selectedAudio: {},
  selectedVideo: {},
  participants: {},
  isRecording: false,
  hostsAndSpeakers: {},
  audience: {},
  count: 0,
  contentTileMap: {},
  noCamera: false,
  userCanShareVideo: false,
  isSharingCamera: false,
  userCanShareScreen: false,
  userSharing: '',
  attSharing: '',
  isSharingScreen: false,
  screenShare: false,
  cameraTileMap: {},
  reconnecting: false,
  type: null,
  isPrivate: false,
};

const isObject = (obj) => obj != null && obj.constructor.name === 'Object';

const sortKeys = (unordered) => {
  if (!isObject(unordered)) return unordered;
  return Object.keys(unordered).sort().reduce(
    (obj, key) => {
      obj[key] = sortKeys(unordered[key]);
      return obj;
    },
    {},
  );
};

const sortFn = (a1, a2) => {
  if (a1.user?.joinedAt > a2.user?.joinedAt) {
    return 1;
  }
  if (a1.user?.joinedAt < a2.user?.joinedAt) {
    return -1;
  }
  return 0;
};

const sorted = (role, participants, attendees) => {
  const moderator = role === 'creator' || role === 'host';
  let atten = attendees;
  atten = atten.map((a) => {
    const usr = participants[a.externalUserId];
    if (usr) {
      return { ...a, user: sortKeys(usr) };
    }
    return a;
  });
  if (moderator) {
    atten.sort(sortFn);
  }
  atten.sort((a1, a2) => {
    if (a1.user && a2.user) {
      const a1Role = getRoleWeight(a1.user.roles);
      const a2Role = getRoleWeight(a2.user.roles);
      if (a2Role === a1Role) {
        return a1.user.joinedAt - a2.user.joinedAt;
      }
      return a2Role - a1Role;
    }
    return 0;
  });
  return atten;
};

export const categorize = (attendees) => {
  const hostsAndSpeakers = attendees
    .filter(
      ({ user: { roles } = {} } = { roles: {} }) => getRole(roles) !== 'audience',
    )
    .filter(({ attendeeId }) => !attendeeId.includes('#content'))
    .filter(({ externalUserId }) => !externalUserId.includes('MediaPipeline'))
    .reduce((map, obj) => ((map[obj.attendeeId] = obj), map), {});
  const audience = attendees
    .filter(
      ({ user: { roles } = { roles: {} } }) => getRole(roles) === 'audience',
    )
    .filter(({ externalUserId }) => !externalUserId.includes('MediaPipeline'))
    .reduce((map, obj) => ((map[obj.attendeeId] = obj), map), {});
  let count = 0;
  count += Object.keys(hostsAndSpeakers).length;
  count += Object.keys(audience).length;
  return {
    hostsAndSpeakers,
    audience,
    count,
  };
};

const reducer = handleActions(
  {
    [setSession]: (state, { payload }) => ({ ...state, session: payload }),
    [updateRoomState]: (state, { payload }) => ({ ...state, ...payload }),
    [addCameraMap]: (state, { payload }) => ({
      ...state,
      cameraTileMap: {
        ...state.cameraTileMap,
        ...payload,
      },
    }),
    [removeContentMap]: (state, { payload }) => {
      const removeContentTile = () => {
        for (const key in state.contentTileMap) {
          if (state.contentTileMap[key] === payload) {
            state.contentTileMap[key] = null;
            state.isSharingScreen = false;
            state.attSharing = '';
          }
        }
      };
      removeContentTile();
      return {
        ...state,
        contentTileMap: {
          ...state.contentTileMap,
        },
      };
    },
    [removeCameraMap]: (state, { payload }) => {
      const removeCameraTile = () => {
        for (const key in state.cameraTileMap) {
          if (state.cameraTileMap[key] === payload) {
            state.cameraTileMap[key] = null;
          }
        }
      };
      removeCameraTile();
      return {
        ...state,
        cameraTileMap: {
          ...state.cameraTileMap,
        },
      };
    },
    [resetRoomState]: () => ({ ...INITIAL_STATE, isLeaving: true }),
    [updateParticipants]: (state, { payload }) => {
      const attendees = sorted(
        state.role,
        payload,
        state.attendees,
      );
      return {
        ...state,
        participants: payload,
        attendees,
        ...categorize(attendees),
      };
    },
    [attendeeLeft]: (state, { payload }) => {
      const attendeeId = payload;
      const attendees = state.attendees.filter(
        (a) => a.attendeeId !== attendeeId,
      );
      return {
        ...state,
        attendees,
        ...categorize(attendees),
      };
    },
    [attendeeJoined]: (state, { payload }) => {
      if (state.attendees.find((a) => a.attendeeId === payload.attendeeId)) {
        return state; // do not update the state, attendee was already there
      }
      let attendees = [];
      attendees = sorted(state.role, state.participants, [
        ...state.attendees,
        { ...payload, muted: true },
      ]);
      return {
        ...state,
        attendees,
        ...categorize(attendees),
      };
    },
    [volumeChanged]: (state, { payload }) => {
      const attendees = state.attendees.map((o) => {
        if (o.attendeeId === payload.attendeeId) {
          const update = {};
          if (payload.volume !== null) {
            update.volume = payload.volume;
          }
          if (payload.muted !== null) {
            update.muted = payload.muted;
          }
          if (payload.signalStrength !== null) {
            update.signalStrength = payload.signalStrength;
          }
          return { ...o, ...update };
        }
        return o;
      });
      return {
        ...state,
        attendees,
        ...categorize(attendees),
      };
    },
  },
  INITIAL_STATE,
);

export default reducer;
