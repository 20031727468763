export const EVENT_UPCOMING_FILTER = 'upcoming';
export const EVENT_MY_TICKETS_FILTER = 'my-tickets';
export const EVENT_MY_EVENTS_FILTER = 'my-events';
export const MY_MEETINGS_FILTER = 'my-meetings';
export const EVENT_PAST_EVENTS_FILTER = 'past-events';

export const POPULAR_PEOPLE_FILTER = 'popular';
export const NEWEST_PEOPLE_FILTER = 'newest';

export const BUY_WITH_FIAT = 'buy-with-fiat';
export const BUY_WITH_BITCOIN = 'buy-with-bitcoin';
export const BUY_WITH_ETH = 'buy-with-eth';
export const BUY_WITH_COINBASE = 'buy-with-coinbase';
