/* eslint-disable no-underscore-dangle */
import { handleActions } from 'redux-actions';
import {
  loadPitches,
  resetPitches,
  loadPitchesComplete,
  setPitchFilter,
  setCurrentPitchSearch,
  setCurrentPitchPage,
} from '../actions/pitchActions';

const INITIAL_STATE = {
  loadingFeed: true,
  hasMoreFeed: false,
  currentPitchFilter: 'upvotes',
  currentFeedPage: 0,
  currentSearch: '',
  feed: [],
};

const reducer = handleActions(
  {
    [setPitchFilter]: (state, { payload }) => ({ ...state, currentPitchFilter: payload }),
    [loadPitches]: (state) => ({ ...state, loadingFeed: true }),
    [resetPitches]: (state) => ({ ...state, feed: INITIAL_STATE.feed, currentFeedPage: 0 }),
    [setCurrentPitchPage]: (state, { payload }) => ({ ...state, currentFeedPage: payload }),
    [setCurrentPitchSearch]: (state, { payload }) => ({ ...state, currentSearch: payload }),
    [loadPitchesComplete]: (state, { payload }) => {
      let feed = payload;
      if (payload.length && state.feed.length > 0) {
        const currentId = state.feed.map((e) => e._id);
        const filterPayload = payload.filter((e) => !currentId.includes(e._id));
        feed = [...state.feed, ...filterPayload];
      } else if (payload.length === 0 && state.feed.length > 0) {
        feed = state.feed;
      }

      return {
        ...state,
        feed,
        hasMoreFeed: payload.length > 0,
        loadingFeed: false,
      };
    },
  },
  INITIAL_STATE,
);

export default reducer;
