/* eslint-disable no-unused-vars */
import { createAction } from 'redux-actions';
import { toast } from 'react-toastify';
import { getStore } from '../../../store';
import desoApi from '../../api/deso';
import newApi from '../../api/newApi';

export const resetDesoPosts = createAction('RESET_DESO_POSTS');
export const setDesoFilter = createAction('SET_DESO_FILTER');
export const updateDesoUserState = createAction('UPDATE_DESO_USER_STATE');
export const loadDesoFeedComplete = createAction('LOAD_DESO_FEED_SUCCESS');
export const loadDesoAppStateDataComplete = createAction('LOAD_DESO_APP_STATE_DATA_COMPLETE');
export const loadDesoAppStateData = createAction('LOAD__DESO_APP_STATE_DATA', async () => {
  try {
    const res = await desoApi.post('get-app-state', {});
    if (res && res.data) {
      getStore().dispatch(loadDesoAppStateDataComplete(res.data));
    }
  } catch (e) {
    if (e) {
      console.log(e);
    }
  }
});
export const desoProfileComplete = createAction('DESO_PROFILE_COMPLETE');
export const desoHasPhoneVerified = createAction('DESO_PHONE_VERIFIED');

export const updateUserMetadata = createAction('UPDATE_USER_METADATA');
export const loadProfileDataComplete = createAction('LOAD_PROFILE_DATA_COMPLETE');
export const loadProfileData = createAction('LOAD_PROFILE_DATA', async () => {
  const { account } = getStore().getState();
  try {
    if (account.user?.deso) {
      const desoResp = await desoApi.post('get-users-stateless', {
        PublicKeysBase58Check: [account.user.deso], SkipHodlings: true,
      });
      const desoUserData = desoResp.data.UserList[0];
      if (desoUserData) {
        getStore().dispatch(loadProfileDataComplete(desoUserData));
      }
      let nodeDeso = 'https://node.deso.org/api/v0';
      if (process.env.DESO_TESTNET === 'true') {
        nodeDeso = null;
      }
      const metaData = await desoApi.get(`get-user-metadata/${account.user.deso}`, nodeDeso);
      getStore().dispatch(updateUserMetadata(metaData.data));
    }
  } catch (e) {
    if (e) {
      console.log(e);
    }
  }
});

export const loadHotFeed = createAction('LOAD_HOT_FEED', async (limit = 10, offset = 0, filter = null) => {
  const { account } = getStore().getState();
  try {
    if (account.user?.deso) {
      const desoResp = await desoApi.post('get-hot-feed', {
        ReaderPublicKeyBase58Check: account.user.deso, ResponseLimit: 50, SeenPosts: [],
      });
      if (desoResp.data.PostsFound) {
        getStore().dispatch(loadDesoFeedComplete(desoResp.data.PostsFound));
      } else {
        getStore().dispatch(loadDesoFeedComplete([]));
      }
    }
  } catch (e) {
    if (e) {
      console.log(e);
    }
  }
});

export const loadGlobalFeed = createAction('LOAD_GLOBAL_FEED', async (lastSeen = '', following = false) => {
  const { account } = getStore().getState();
  try {
    if (account.user?.deso) {
      const desoResp = await desoApi.post('get-posts-stateless', {
        PostHashHex: lastSeen,
        AddGlobalFeedBool: true,
        FetchSubcomments: false,
        GetPostsByDESO: false,
        GetPostsForFollowFeed: following,
        GetPostsForGlobalWhitelist: true,
        MediaRequired: false,
        NumToFetch: 3,
        OrderBy: 'newest',
        PostContent: '',
        ReaderPublicKeyBase58Check: account.user.deso,
        SeenPosts: [],
      });
      if (desoResp.data.PostsFound) {
        const lastItem = desoResp.data.PostsFound[desoResp.data.PostsFound.length - 1];
        getStore().dispatch(loadDesoFeedComplete(desoResp.data.PostsFound));
      }
    }
  } catch (e) {
    if (e) {
      console.log(e);
    }
  }
});

export const blockDesoUser = createAction('BLOCK_DESO_USER', async (jwt, blockedPk, unblock) => {
  /// WIP ///
  console.log(blockedPk);
  const { account } = getStore().getState();
  jwt(account.user.deso).subscribe(async (jwtRes) => {
    const pk = account.user.deso;
    console.log(pk);
    try {
      const desoTokenRes = await newApi.post('deso-token', {
        jwt: jwtRes.jwt,
        pk,
      });
      console.log(desoTokenRes);
      if (desoTokenRes) {
        const { token } = desoTokenRes.data;
        console.log(token);
        const desoResp = await desoApi.post('block-public-key', {
          PublicKeyBase58Check: account.user.deso,
          BloclPublicKeyBase58Check: blockedPk,
          Unblock: unblock,
          JWT: token,
        });
        console.log(desoResp);
        if (desoResp) {
          // getStore().dispatch(loadProfileDataComplete(desoUserData));
        }
      }
    } catch (err) {
      return err;
    }
    return false;
  });
});
