import router from 'next/router';
import OneSignal from 'react-onesignal';
import Web3 from 'web3';
import { toast } from 'react-toastify';
import { AUTH, MAIN } from './types';
import firebase from '../../../src/firebase';
import { loadAccount } from './accountActions';
import newApi from '../../api/newApi';
import desoApi from '../../api/deso';
import { getStore } from '../../../store';

const logoutSuccess = (dispatch) => {
  dispatch({
    type: AUTH.LOGOUT_SUCCESS,
    payload: true,
  });
  // setTimeout(() => { Router.push('/'); }, 200);
};

const loginSuccess = async (dispatch, currentUser) => {
  const stateInfo = getStore().getState();
  if (stateInfo.main.videoModalDismissed && stateInfo.main.appModalDismissed === undefined) {
    dispatch({
      type: MAIN.RESET_DISMISS_APP_MODAL,
    });
  }
  dispatch({
    type: AUTH.LOGIN_SUCCESS,
    payload: currentUser || [],
  });
};

const loginFail = (dispatch, error) => {
  dispatch({
    type: AUTH.LOGIN_FAIL,
    payload: error,
  });
};

export const openProModal = () => async (dispatch) => {
  dispatch({ type: AUTH.OPEN_PROMODAL_AFTER_LOGIN });
};

export const signupFail = (error) => async (dispatch) => {
  dispatch({
    type: AUTH.SIGNUP_FAIL,
    payload: error,
  });
};

export const updateIdToken = (idToken) => async (dispatch) => {
  dispatch({
    type: AUTH.SET_IDTOKEN_SUCCESS,
    payload: idToken || undefined,
  });
  const currentUser = await firebase.auth().currentUser;
  dispatch(loadAccount(currentUser.uid));
};

export const checkSession = () => (dispatch) => {
  // console.log('checkSession');
  try {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        loginSuccess(dispatch, user);
        const idToken = await user.getIdToken();
        await dispatch(updateIdToken(idToken));
      } else {
        logoutSuccess(dispatch);
      }
    });
  } catch (err) {
    // console.log("EE", err);
  }
};

export const clearErrors = () => async (dispatch) => {
  dispatch({ type: AUTH.CLEAR_ERROR });
};

export const redirectAfterOnboarding = (pathname) => async (dispatch) => {
  dispatch({
    type: MAIN.REDIRECT_AFTER_ONBOARDING,
    payload: pathname,
  });
};

export const skipOnboardingFlow = () => async (dispatch) => {
  dispatch({
    type: AUTH.SKIP_ONBOARDING_FLOW,
  });
};

export const emailLogin = (email, password, redirect = false) => async (dispatch) => {
  if (!email || !password) {
    loginFail(dispatch, 'REQUIRED_USER_AND_PASS');
    return;
  }
  // if (updateToPro) {
  //   console.log('helllo from authActions', updateToPro);
  //   openModal();
  // }
  firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then(async () => {
      if (redirect) await dispatch(redirectAfterOnboarding(redirect));
    })
    .catch((error) => {
      const errorCode = error.code;
      if (
        errorCode === 'auth/wrong-password'
          || errorCode === 'auth/user-not-found'
          || errorCode === 'auth/invalid-email'
          || errorCode === 'auth/user-disabled'
      ) {
        loginFail(dispatch, 'WRONG_USER_OR_PASS');
      } else if (errorCode === 'auth/too-many-requests') {
        loginFail(dispatch, 'TOO_MANY_WRONG_ATTEMPTS');
      } else {
        loginFail(dispatch, errorCode);
      }
    });
};

export const passwordLessLogin = (redirect) => async (dispatch) => {
  if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
    let email = window?.localStorage?.getItem('emailForSignIn');
    if (!email) {
      email = window.prompt('Please provide your email for confirmation');
    }
    // The client SDK will parse the code from the link for you.
    if (redirect) dispatch(redirectAfterOnboarding(redirect));
    firebase
      .auth()
      .signInWithEmailLink(email, window.location.href)
      .then(() => {
        window.localStorage.removeItem('emailForSignIn');
      })
      .catch((error) => {
        toast.error(error.message);
        router.replace('/');
      });
  }
};

export const getDesoFbToken = (launch, jwt) => new Promise((resolve, reject) => {
  launch('log-in', { accessLevelRequest: 3 }).subscribe((res) => {
    const pk = res.publicKeyAdded;
    jwt(res.users[pk]).subscribe(async (jwtRes) => {
      try {
        const desoTokenRes = await newApi.post('deso-token', {
          jwt: jwtRes.jwt,
          pk,
        });
        if (desoTokenRes) {
          const { token } = desoTokenRes.data;
          const desoResp = await desoApi.post('get-users-stateless', {
            PublicKeysBase58Check: [pk],
            SkipHodlings: true,
          });
          const desoUser = desoResp.data.UserList[0];
          const profile = {
            photoURL: `https://deso.joinentre.com/api/v0/get-single-profile-picture/${pk}`,
          };
          if (process.env.DESO_TESTNET === 'true') {
            // eslint-disable-next-line no-param-reassign
            profile.photoURL = `https://deso-testnet.joinentre.com/api/v0/get-single-profile-picture/${pk}`;
          }
          if (desoUser.ProfileEntryResponse) {
            profile.displayName = desoUser.ProfileEntryResponse.Username;
          }
          // Case new deso accounts without profile
          // get-single-profile above is not returning a valid img link
          if (!desoUser.ProfileEntryResponse) {
            profile.photoURL = null;
          }
          resolve({
            token,
            profile,
            pk,
            credentials: res.users[pk],
          });
        }
      } catch (err) {
        reject(err);
      }
    });
  });
});

export const desoLogin = (launch, jwt, redirect = false) => async (dispatch) => {
  try {
    const { token, profile, credentials } = await getDesoFbToken(launch, jwt);
    return firebase
      .auth()
      .signInWithCustomToken(token)
      .then(async (response) => {
        await response.user.updateProfile(profile);
        localStorage.setItem('identityUsers', JSON.stringify(credentials));
        if (redirect) await dispatch(redirectAfterOnboarding(redirect));
      })
      .catch((error) => {
        const errorCode = error.code;
        loginFail(dispatch, errorCode);
      });
  } catch (err) {
    loginFail(dispatch, err);
  }
  return false;
};

export const appleLogin = (redirect = false) => async (dispatch) => {
  const provider = new firebase.auth.OAuthProvider('apple.com');
  provider.addScope('email');
  provider.addScope('name');
  firebase.auth().useDeviceLanguage();
  firebase
    .auth()
    .signInWithPopup(provider)
    .then(async () => {
      if (redirect) await dispatch(redirectAfterOnboarding(redirect));
    })
    .catch((error) => {
      const errorCode = error.code;
      if (
        errorCode === 'auth/wrong-password'
          || errorCode === 'auth/user-not-found'
          || errorCode === 'auth/invalid-email'
          || errorCode === 'auth/user-disabled'
      ) {
        loginFail(dispatch, 'WRONG_USER_OR_PASS');
      } else {
        loginFail(dispatch, errorCode);
      }
    });
};

export const googleLogin = (redirect = false) => async (dispatch) => {
  const provider = new firebase.auth.GoogleAuthProvider();
  // provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
  firebase.auth().useDeviceLanguage();
  firebase
    .auth()
    .signInWithPopup(provider)
    .then(async () => {
      if (redirect) await dispatch(redirectAfterOnboarding(redirect));
    })
    .catch((error) => {
      const errorCode = error.code;
      if (
        errorCode === 'auth/wrong-password'
          || errorCode === 'auth/user-not-found'
          || errorCode === 'auth/invalid-email'
          || errorCode === 'auth/user-disabled'
      ) {
        loginFail(dispatch, 'WRONG_USER_OR_PASS');
      } else {
        loginFail(dispatch, errorCode);
      }
    });
};

// Using this for eth new accounts
export const ethSignUp = (redirect = false, accounts, provider) => async (dispatch) => {
  try {
    const account = accounts[0];
    const res = await newApi.get(`eth/sig?address=${account}`);
    if (res.data) {
      const web3 = new Web3(provider);
      const signature = await web3.eth.personal.sign(res.data.stringToSign, account);
      const res2 = await newApi.post('eth/sig-token', {
        address: account,
        signedMessage: signature,
      });
      if (res2.data && res2.data.token) {
        await newApi.put('user', { eth: account });
        // Authenticate and create user with custom token
        await firebase.auth().signInWithCustomToken(res2.data.token);
        if (redirect) await dispatch(redirectAfterOnboarding(redirect));
        return true;
      }
    }
    return false;
  } catch (error) {
    const errorCode = error.code;
    console.error(error.message);
    loginFail(dispatch, errorCode);
    return error.message;
  }
};

// used only for coinbase-mobile url (mobile app)
export const mobileEthSignUp = (accounts, provider) => async (dispatch) => {
  try {
    const account = accounts[0];
    const res = await newApi.get(`eth/sig?address=${account}`);
    if (res.data) {
      const web3 = new Web3(provider);
      const signature = await web3.eth.personal.sign(res.data.stringToSign, account);
      const res2 = await newApi.post('eth/sig-token', {
        address: account,
        signedMessage: signature,
      });
      if (res2.data && res2.data.token) {
        return res2.data.token;
      }
    }
    return false;
  } catch (error) {
    const errorCode = error.code;
    console.error(error.message);
    loginFail(dispatch, errorCode);
    return error.message;
  }
};

// Using this eth login
export const ethLogin = (accounts, provider) => async (dispatch) => {
  try {
    const account = accounts[0];
    const res = await newApi.get(`eth/sig?address=${account}`);
    if (res.data) {
      const web3 = new Web3(provider);
      const signature = await web3.eth.personal.sign(res.data.stringToSign, account);
      const res2 = await newApi.post('eth/sig-token', {
        address: account,
        signedMessage: signature,
      });
      if (res2.data && res2.data.token) {
        await newApi.put('user', { eth: account });
        const currentUser = await firebase.auth().currentUser;
        dispatch(loadAccount(currentUser.uid));
        return true;
      }
    }
    return false;
  } catch (error) {
    const errorCode = error.code;
    return loginFail(dispatch, errorCode);
  }
};

export const checkForUsername = (username) => async () => {
  const response = await newApi.get(`user/check?username=${username}`);
  if (response && response.data.data !== undefined) {
    return response.data.data.isUserPresent;
  }
  return true;
};

export const recoverPass = (email) => async () => {
  await newApi.get(`password?email=${email}`);
};

export const guestLogin = (fullName) => async (dispatch) => {
  firebase.auth().useDeviceLanguage();
  firebase
    .auth()
    .signInAnonymously()
    .then(async (response) => {
      await response.user.updateProfile({
        displayName: fullName,
      });
    })
    .catch((error) => {
      const errorCode = error.code;
      if (
        errorCode === 'auth/wrong-password'
        || errorCode === 'auth/user-not-found'
        || errorCode === 'auth/invalid-email'
        || errorCode === 'auth/user-disabled'
      ) {
        loginFail(dispatch, 'WRONG_USER_OR_PASS');
      } else {
        loginFail(dispatch, errorCode);
      }
    });
};

export const resetPass = (secToken, password) => async () => newApi.post('password', {
  uniqueId: secToken,
  password,
});

export const emailSignup = (newUser, redirect = false) => async (dispatch) => {
  try {
    const response = await firebase
      .auth()
      .createUserWithEmailAndPassword(newUser.email, newUser.password);
    await response.user.updateProfile({ displayName: newUser.fullName });
    if (redirect) await dispatch(redirectAfterOnboarding(redirect));
  } catch (error) {
    const errorCode = error.code;
    if (errorCode === 'auth/weak-password') {
      dispatch(signupFail('WEAK_PASSWORD'));
    } else if (errorCode === 'auth/email-already-in-use') {
      dispatch(signupFail('DUPLICATED_EMAIL'));
    } else {
      dispatch(signupFail(errorCode));
    }
  }
};

export const logOut = () => async () => {
  console.log('fdsfdsaf');
  try {
    firebase.auth().signOut();
  } catch (err) {
    // something happened
  }
  OneSignal.removeExternalUserId();
  localStorage.removeItem('identityUsers');
  localStorage?.removeItem('walletconnect');
  if (window) {
    window.location.href = '/';
  }
};
