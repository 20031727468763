/* eslint-disable no-lonely-if */
/* eslint-disable no-console */
/* eslint-disable consistent-return, jsx-a11y/media-has-caption, max-len */
import React, {
  createContext, useEffect, useRef,
} from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';
import { DefaultModality, MeetingSessionStatusCode } from 'amazon-chime-sdk-js';
import Router from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { getParticipantJoinTicket, connectToSession } from '../helpers/chime';
import firebase from '../firebase';
import { getRole } from '../components/connect/utils';
import {
  setSession, updateRoomState, resetRoomState, attendeeLeft,
  attendeeJoined, volumeChanged, updateParticipants, removeCameraMap, addCameraMap, removeContentMap,
} from '../../common/data/actions';
import AudioInputDevice from '../components/connect/AudioInputDevice';
import AudioSpeaker from '../components/connect/AudioSpeaker';

const firestore = firebase.firestore();
const MeetingSessionContext = createContext(null);

export const MeetingSessionContextProvider = ({ children }) => {
  const user = useSelector((s) => s.account.user);
  const dispatch = useDispatch();
  const roomId = useSelector((state) => state.rooms.roomId);
  const minimized = useSelector((state) => state.rooms.minimized);
  const isSharingScreen = useSelector((state) => state.rooms.isSharingScreen);
  const roleRef = useRef(null);
  const sessionRef = useRef(false);
  const isLeavingRef = useRef(false);
  const queueListener = useRef(null);
  const attendeePrescenceHandler = useRef(null);
  const activeSpeakerCallback = useRef(null);
  const canUnmuteCallback = useRef(null);
  const isMinimized = useRef(false);
  const isReconnecting = useRef(false);

  useEffect(() => () => {
    isMinimized.current = !minimized;
  }, [minimized]);

  const getVideoTiles = () => {
    const observer = {
      videoTileDidUpdate: (tileState) => {
        if (!tileState.boundAttendeeId) {
          return;
        }
        // Screen Share
        if (tileState.isContent) {
          const data = {};
          data[tileState.boundExternalUserId] = tileState.tileId;
          dispatch(updateRoomState({
            contentTileMap: data, attSharing: tileState.boundAttendeeId.split('#')[0], screenShare: true, userSharing: tileState.boundExternalUserId,
          }));
        } else {
          // Video | Camera
          if (tileState && tileState.boundExternalUserId) {
            if (tileState.boundVideoStream && tileState.boundVideoStream.active) {
              if (tileState.boundVideoElement === null) {
                dispatch(addCameraMap({ [tileState.boundExternalUserId]: tileState.tileId }));
              }
            } else {
              dispatch(removeCameraMap(tileState.tileId));
            }
          }
        }
      },
      videoTileWasRemoved: (tileId) => {
        // Remove tile when someone stops video or screen share
        // ATM is not possible to know if it's a screen share or a camera, we are removing from both
        dispatch(removeContentMap(tileId));
        dispatch(removeCameraMap(tileId));
      },
    };

    sessionRef.current.audioVideo.addObserver(observer);

    return () => {
      sessionRef.current.audioVideo.removeObserver(observer);
    };
  };

  const leaveSession = async (mounted = true) => {
    await dispatch(updateRoomState({ isLeaving: true }));
    console.log('MSC leaveSessionCalled', isLeavingRef.current);
    // if (isLeavingRef.current) return;
    isLeavingRef.current = true;
    if (sessionRef.current) {
      const presentAttendeeId = sessionRef.current.configuration.credentials.attendeeId;
      sessionRef.current.audioVideo.realtimeUnsubscribeFromVolumeIndicator();
      sessionRef.current.audioVideo.realtimeUnsubscribeToAttendeeIdPresence(attendeePrescenceHandler.current);
      sessionRef.current.audioVideo.unsubscribeFromActiveSpeakerDetector(activeSpeakerCallback.current);
      sessionRef.current.audioVideo.realtimeUnsubscribeFromVolumeIndicator(presentAttendeeId);
      sessionRef.current.audioVideo.realtimeUnsubscribeToSetCanUnmuteLocalAudio(canUnmuteCallback.current);
      sessionRef.current.audioVideo.stop();
      sessionRef.current.audioVideo.stopContentShare();
      sessionRef.current.audioVideo.stopLocalVideoTile();
      sessionRef.current.audioVideo.chooseAudioInputDevice(null);
      sessionRef.current.audioVideo.chooseAudioOutputDevice(null);
      sessionRef.current.audioVideo.chooseVideoInputDevice(null);
      dispatch(updateRoomState({
        isSharingCamera: false,
        joinUnmuted: false,
        joinWithCameraOn: false,
        selectedAudio: {},
        selectedVideo: {},
      }));
      if (queueListener.current) {
        queueListener.current();
      }
      localStorage.removeItem('roomLSData');
    }
    // Reset state if mounted ( called from a button or UI )
    if (mounted) {
      dispatch(resetRoomState());
      roleRef.current = null;
      sessionRef.current = false;
      window.localStorage.removeItem('roomLSData');
    }
    queueListener.current = null;
    activeSpeakerCallback.current = null;
    attendeePrescenceHandler.current = null;
    canUnmuteCallback.current = null;
    console.log('MSC left session');
  };

  const listenData = (rId) => {
    if (!queueListener.current) {
      console.log('MSC listening fb room: ', rId);
      queueListener.current = firestore.collection('rooms')
        .doc(rId)
        .onSnapshot((data) => {
          if (!data.exists) {
            // room deleted from fb
            return () => {};
          }
          console.log('MSC onSnapshot');
          const roomData = data.data();
          if (roomData.participants && roomData.participants[user.id]) {
            const userRoles = roomData.participants[user.id].roles;
            const myRole = getRole(userRoles);
            dispatch(updateParticipants(roomData.participants));
            dispatch(updateRoomState({
              isRecording: roomData.isRecording,
              currentUser: roomData.participants[user.id],
              role: myRole,
              topic: roomData.topic,
              type: roomData.type,
              isPrivate: roomData.private,
            }));
            if (roleRef.current !== myRole) { // role changed, do something...
              roleRef.current = myRole;
              switch (myRole) {
                case 'creator':
                case 'host':
                  if (sessionRef.current.audioVideo) {
                    sessionRef.current.audioVideo.realtimeSetCanUnmuteLocalAudio(true);
                  }
                  dispatch(updateRoomState({
                    userCanShareScreen: true,
                    userCanShareVideo: true,
                    isSharingCamera: false,
                  }));
                  break;
                case 'speaker': // allow this roles to unmute them selves
                  dispatch(updateRoomState({
                    userCanShareScreen: true,
                    userCanShareVideo: true,
                    isSharingCamera: false,
                  }));
                  if (sessionRef.current.audioVideo) {
                    sessionRef.current.audioVideo.realtimeSetCanUnmuteLocalAudio(true);
                    sessionRef.current.audioVideo.stopLocalVideoTile();
                    try {
                      sessionRef.current.audioVideo.chooseVideoInputDevice(null);
                    } catch (err) { console.log('ERR', err); }
                  }
                  break;
                case 'audience':
                default:
                  dispatch(updateRoomState({
                    userCanShareScreen: false,
                    userCanShareVideo: false,
                    isSharingCamera: false,
                  }));
                  if (sessionRef.current.audioVideo) {
                    sessionRef.current.audioVideo.stopContentShare();
                    sessionRef.current.audioVideo.realtimeMuteLocalAudio();
                    sessionRef.current.audioVideo.realtimeSetCanUnmuteLocalAudio(false);
                    sessionRef.current.audioVideo.stopLocalVideoTile();
                    try {
                      sessionRef.current.audioVideo.chooseVideoInputDevice(null);
                    } catch (err) { console.log('ERR', err); }
                  }
                  break;
              }
            }
          }
          dispatch(updateRoomState({ isJoining: false }));
        });
    }
  };

  const muteMember = () => {
    sessionRef.current.audioVideo.realtimeMuteLocalAudio();
  };

  const joinSession = async (rId, storedTicket = null, storedMeeting = null) => {
    console.log('MSC currRoom: ', roomId, 'joining: ', rId, 'leaving: ', isLeavingRef.current);
    if (roomId !== null && roomId !== rId) {
      leaveSession();
      while (isLeavingRef.current) {
        // eslint-disable-next-line no-await-in-loop
        await new Promise((res) => setTimeout(res, 200));
      }
      return;
    }
    console.log('MSC starting session');
    if (sessionRef.current) return dispatch(updateRoomState({ minimized: false }));
    dispatch(updateRoomState({ isJoining: true, roomId: rId }));
    try {
      let meeting;
      let ticket;
      if (storedMeeting && storedTicket) {
        meeting = storedMeeting;
        ticket = storedTicket;
      } else {
        const participant = await getParticipantJoinTicket(rId);
        meeting = participant.meeting;
        ticket = participant.ticket;
        window.localStorage.setItem('roomLSData', JSON.stringify({ rId, meeting, ticket }));
      }
      console.debug('meeting', meeting, ticket);
      // Connecto to chime meeting
      sessionRef.current = await connectToSession(meeting, ticket);
      dispatch(setSession(sessionRef.current));
      getVideoTiles();
      sessionRef.current.audioVideo.addObserver({
        audioVideoDidStartConnecting: (reconnecting) => {
          dispatch(updateRoomState({ reconnecting }));
          isReconnecting.current = reconnecting;
          console.debug('audioVideoDidStartConnecting', reconnecting);
        },
        audioVideoDidStart: () => {
          console.debug('audioVideoDidStart');
          dispatch(updateRoomState({ reconnecting: false }));
          if (!isReconnecting.current) {
            listenData(rId);
            sessionRef.current.audioVideo.realtimeMuteLocalAudio();
          }
          isReconnecting.current = false;
        },
        audioVideoDidStop: (status) => {
          console.log('MSC audioVideoDidStop');
          console.log(status);
          let message = `Session stopped with status: ${status.statusCode()}. Reloading...`;
          isLeavingRef.current = false;
          // dispatch(updateRoomState({ isLeaving: false }));
          switch (status.statusCode()) {
            case MeetingSessionStatusCode.AudioCallEnded:
              message = 'Room has ended!';
              toast.info(message);
              console.log('MSC 1');
              if (!isMinimized.current) {
                Router.push('/feed');
                // window.location.replace('/feed');
              }
              leaveSession();
              break;
            case MeetingSessionStatusCode.Left:
              console.log('MSC Left');
              // leaveSession();
              if (!minimized) {
                // Router.push('/feed');
                // window.location.replace('/feed');
              }
              break;
            case MeetingSessionStatusCode.AudioJoinedFromAnotherDevice:
              console.log('MSC AudioJoinedFromAnotherDevice');
              leaveSession();
              break;
            default:
              // toast.warning(message);
              // Router.reload();
              console.log('MSC 2', status);
              leaveSession();
              break;
          }
        },
      });

      // Start session
      sessionRef.current.audioVideo.start();
      // Meeting Presence Listener
      attendeePrescenceHandler.current = (attendeeId, present, externalUserId, dropped) => {
        // dispatch(updateRoomState({ isJoining: false }));
        console.debug(`${attendeeId} present = ${present} dropped = ${dropped} (${externalUserId})`);
        const isContentAttendee = new DefaultModality(attendeeId)
          .hasModality(DefaultModality.MODALITY_CONTENT); // Is Content ( ScreenShare )
        console.log(isContentAttendee);
        const isSelfAttendee = new DefaultModality(attendeeId).base()
        === sessionRef.current.configuration.credentials.attendeeId; // Is Me ...
        console.log(isSelfAttendee);
        if (isContentAttendee && present) {
          // TODO: check this bc this could be video feed from users too ( potential bug )
          dispatch(updateRoomState({ userSharing: externalUserId, attSharing: attendeeId, isSharingScreen: externalUserId === user.id }));
          if (isMinimized.current) {
            sessionRef.current.audioVideo.stopContentShare();
          }
          return null;
        }

        const attendee = {
          attendeeId,
          present,
          externalUserId,
          isContentAttendee,
          isSelfAttendee,
        };
        if (!present) {
          dispatch(attendeeLeft(attendeeId));
        } else {
          dispatch(attendeeJoined(attendee));
          sessionRef.current.audioVideo.realtimeSubscribeToVolumeIndicator(
            attendeeId,
            (attId, volume, muted, signalStrength) => {
              dispatch(volumeChanged({
                attendeeId: attId,
                volume,
                muted,
                signalStrength,
              }));
            },
          );
        }
      };
      sessionRef.current.audioVideo.realtimeSubscribeToAttendeeIdPresence(attendeePrescenceHandler.current);
      // Mute/Unmute Listener
      const presentAttendeeId = sessionRef.current.configuration.credentials.attendeeId;
      sessionRef.current.audioVideo.realtimeSubscribeToVolumeIndicator(
        presentAttendeeId,
        (attendeeId, volume, muted, signalStrength) => { // eslint-disable-line no-unused-vars
          if (muted === null) {
            // muted state has not changed, ignore volume and signalStrength changes
            return;
          }
          dispatch(updateRoomState({ isMuted: muted }));
        },
      );

      // Can unmute listner
      canUnmuteCallback.current = (cUnm) => {
        dispatch(updateRoomState({ canUnmute: cUnm }));
      };
      sessionRef.current.audioVideo.realtimeSubscribeToSetCanUnmuteLocalAudio(canUnmuteCallback.current);

      // Most active speaker
      // activeSpeakerCallback.current = (attendeeIds) => {
      // if (attendeeIds.length) {
      // const attId = attendeeIds[0];
      // setSortedAttendees((at) => at
      // .map((o) => {
      // const mostActive = o.attendeeId === attId;
      // return { ...o, mostActive };
      // }));
      // console.debug(`${attendeeIds[0]} is the most active speaker`);
      // }
      // };
      // sessionRef.current.audioVideo.subscribeToActiveSpeakerDetector(
      // new DefaultActiveSpeakerPolicy(),
      // activeSpeakerCallback.current,
      // );

      // const cUnm = sessionRef.current.audioVideo.realtimeCanUnmuteLocalAudio();
      // setCanUnmute(cUnm);

      sessionRef.current.audioVideo.realtimeMuteLocalAudio();
      sessionRef.current.audioVideo.realtimeSetCanUnmuteLocalAudio(false);

      dispatch(updateRoomState({ isMuted: true }));
    } catch (err) {
      toast.error(err.message || 'Error connecting to meeting.');

      dispatch(updateRoomState({
        session: null,
        isJoining: false,
      }));
      sessionRef.current = false;
      window.localStorage.removeItem('roomLSData');
    }
  };

  const toggleShareScreen = async () => {
    if (isSharingScreen) {
      dispatch(updateRoomState({
        userSharing: '', attSharing: '', isSharingScreen: false, screenShare: false,
      }));
      return sessionRef.current.audioVideo.stopContentShare();
    }
    return sessionRef.current.audioVideo.startContentShareFromScreenCapture();
  };

  useEffect(() => {
    const handleRouteChange = (url) => {
      const isRooms = url.includes('/room/');
      dispatch(updateRoomState({ isMinimized: !isRooms }));
    };
    Router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
      console.log('MSC unmount');
      isLeavingRef.current = false;
      leaveSession(false);
      // dispatch(updateRoomState({ isLeaving: false }));
    };
  }, []);
  return (
    <>
      <MeetingSessionContext.Provider value={{
        joinSession,
        leaveSession,
        toggleShareScreen,
        isLeavingRef,
        muteMember,
      }}
      >
        { typeof window !== 'undefined' ? (
          <>
            <AudioInputDevice />
            <AudioSpeaker />
          </>
        ) : null }
        {children}
      </MeetingSessionContext.Provider>
    </>
  );
};

MeetingSessionContextProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export default MeetingSessionContext;
