export const experienceList = [
  {
    value: 'Student (0-1 year)',
    label: 'Student (0-1 year)',
  },
  {
    value: 'Entry (1-3 years)',
    label: 'Entry (1-3 years)',
  },
  {
    value: 'Junior (3-5 years)',
    label: 'Junior (3-5 years)',
  },
  {
    value: 'Intermediate (5-10 years)',
    label: 'Intermediate (5-10 years)',
  },
  {
    value: 'Senior (10+ years)',
    label: 'Senior (10+ years)',
  },
];

export const equityLevelList = [
  {
    value: 'None',
    label: 'None',
  },
  {
    value: '<0.5%',
    label: '<0.5%',
  },
  {
    value: '0.5% - 2%',
    label: '0.5% - 2%',
  },
  {
    value: '>2.0%',
    label: '>2.0%',
  },
];

export const expectedSalaryList = [
  {
    value: '< $1,000',
    label: '< $1,000',
  },
  {
    value: '$1,000-2,500',
    label: '$1,000-2,500',
  },
  {
    value: '$2,500-4,000',
    label: '$2,500-4,000',
  },
  {
    value: '$4,000-$6,000',
    label: '$4,000-$6,000',
  },
  {
    value: '$6,000-$8,000',
    label: '$6,000-$8,000',
  },
  {
    value: '$8,000-$10,000',
    label: '$8,000-$10,000',
  },
  {
    value: '$10k +',
    label: '$10k +',
  },
];

export const workDesiredList = [
  {
    value: 'Full Time',
    label: 'Full Time',
  },
  {
    value: 'Part Time',
    label: 'Part Time',
  },
  {
    value: 'Freelance',
    label: 'Freelance',
  },
  {
    value: 'Internship',
    label: 'Internship',
  },
];

export const workList = [
  {
    value: 'Full Time',
    label: 'Full Time',
  },
  {
    value: 'Part Time',
    label: 'Part Time',
  },
  {
    value: 'Freelance',
    label: 'Freelance',
  },
  {
    value: 'Not working',
    label: 'Not working',
  },
];
export const startDateList = [
  {
    value: 'Immediately',
    label: 'Immediately',
  },
  {
    value: 'One week',
    label: 'One week',
  },
  {
    value: 'Two weeks',
    label: 'Two weeks',
  },
  {
    value: 'One month',
    label: 'One month',
  },
  {
    value: 'More than one month',
    label: 'More than one month',
  },

];

export const heardEntreList = [
  {
    value: 'Entre',
    label: 'Entre',
  },
  {
    value: 'Indeed',
    label: 'Indeed',
  },
  {
    value: 'Linkedin',
    label: 'Linkedin',
  },
  {
    value: 'Upwork',
    label: 'Upwork',
  },
  {
    value: 'ZipRecruiter',
    label: 'ZipRecruiter',
  },
  {
    value: 'Twitter',
    label: 'Twitter',
  },
  {
    value: 'TikTok',
    label: 'TikTok',
  },
  {
    value: 'Instagram',
    label: 'Instagram',
  },
  {
    value: 'Facebook',
    label: 'Facebook',
  },
  {
    value: 'Referred',
    label: 'Referred',
  },
  {
    value: 'RemoteOK',
    label: 'RemoteOK',
  },
  {
    value: 'Chegg',
    label: 'Chegg',
  },
  {
    value: 'Handshake',
    label: 'Handshake',
  },
  {
    value: 'Henry',
    label: 'Henry',
  },
  {
    value: 'Monster',
    label: 'Monster',
  },
];

export const initialList = [
  {
    value: 'Front End Developer',
    label: 'Front End Developer',
  },
  {
    value: 'Fullstack Developer',
    label: 'Fullstack Developer',
  },
  {
    value: 'React Native Developer',
    label: 'React Native Developer',
  },
  {
    value: 'iOS Engineer',
    label: 'iOS Engineer',
  },
  {
    value: 'Android Engineer',
    label: 'Android Engineer',
  },
  {
    value: 'Data Engineer',
    label: 'Data Engineer',
  },
  {
    value: 'UX/UI Designer',
    label: 'UX/UI Designer',
  },
  {
    value: 'Content Creator',
    label: 'Content Creator',
  },
  {
    value: 'Content Manager',
    label: 'Content Manager',
  },
  {
    value: 'Community & Events Manager',
    label: 'Community & Events Manager',
  },
  {
    value: 'Partnerships Manager',
    label: 'Partnerships Manager',
  },
  {
    value: 'Product Manager',
    label: 'Product Manager',
  },
  {
    value: 'Operations Manager',
    label: 'Operations Manager',
  },
  {
    value: 'Account Manager',
    label: 'Account Manager',
  },
  {
    value: 'Business Development Representative',
    label: 'Business Development Representative',
  },
  {
    value: 'Accounting Manager',
    label: 'Accounting Manager',
  },
  {
    value: 'Account Executive',
    label: 'Account Executive',
  },
  {
    value: 'Digital Marketing Specialist',
    label: 'Digital Marketing Specialist',
  },
  {
    value: 'Graphic Designer',
    label: 'Graphic Designer',
  },
  {
    value: 'Blockchain Engineer',
    label: 'Blockchain Engineer',
  },
  {
    value: 'Data Scientist',
    label: 'Data Scientist',
  },
  {
    value: 'Marketing Manager',
    label: 'Marketing Manager',
  },
  {
    value: 'Event Organizer',
    label: 'Event Organizer',
  },
  {
    value: 'Director of Finance',
    label: 'Director of Finance',
  },
  {
    value: 'Director of HR',
    label: 'Director of HR',
  },
  {
    value: 'Customer Experience Manager',
    label: 'Customer Experience Manager',
  },
  {
    value: 'Senior Software Engineer',
    label: 'Senior Software Engineer',
  },
  {
    value: 'Backend Engineer',
    label: 'Backend Engineer',
  },
  {
    value: 'Photographer',
    label: 'Photographer',
  },
  {
    value: 'Costumer Success Manager',
    label: 'Costumer Success Manager',
  },
  {
    value: 'Sale Development Representative',
    label: 'Sale Development Representative',
  },
  {
    value: 'Product Marketing Manager',
    label: 'Product Marketing Manager',
  },
  {
    value: 'Technical Recruiter',
    label: 'Technical Recruiter',
  },
  {
    value: 'User Researcher',
    label: 'User Researcher',
  },
  {
    value: 'Product Designer',
    label: 'Product Designer',
  },
  {
    value: 'Engineering Manager',
    label: 'Engineering Manager',
  },
  {
    value: 'DevOps Engineer',
    label: 'DevOps Engineer',
  },
  {
    value: 'Security Engineer',
    label: 'Security Engineer',
  },
  {
    value: 'Growth Hacker',
    label: 'Growth Hacker',
  },
  {
    value: 'Copywriter',
    label: 'Copywriter',
  },
  {
    value: 'PHP Developer',
    label: 'PHP Developer',
  },
];

export const paymentList = [
  {
    value: 'Unpaid',
    label: 'Unpaid',
  },
  {
    value: 'Fixed',
    label: 'Fixed',
  },
  {
    value: 'Hourly',
    label: 'Hourly',
  },
  {
    value: 'Monthly',
    label: 'Monthly',
  },
  {
    value: 'Annually',
    label: 'Annually',
  },
];

export const equityList = [
  {
    value: { min: '0', max: '0' },
    label: 'None',
  },
  {
    value: { min: '0', max: '0.5' },
    label: '0 - 0.5%',
  },
  {
    value: { min: '0.5', max: '2' },
    label: '0.5 - 2%',
  },
  {
    value: { min: '2', max: '5' },
    label: '2 - 5%',
  },
  {
    value: { min: '5', max: '10' },
    label: '5 - 10%',
  },
  {
    value: { min: '10', max: '+10' },
    label: '10+%',
  },
];

export const contractList = [
  {
    value: 'Co-founder',
    label: 'Co-founder',
  },
  {
    value: 'Full Time',
    label: 'Full Time',
  },
  {
    value: 'Part Time',
    label: 'Part Time',
  },
  {
    value: 'Freelance / Contract',
    label: 'Freelance / Contract',
  },
  {
    value: 'Commission',
    label: 'Commission',
  },
  {
    value: 'Internship / Volunteer',
    label: 'Internship / Volunteer',
  },
  {
    value: 'Advisor',
    label: 'Advisor',
  },
];

export const skillsList = [
  { id: 0, name: 'Accounting' },
  { id: 1, name: 'Bookkeeping' },
  { id: 2, name: 'Business Analysis' },
  { id: 3, name: 'Financial Analysis & Modeling' },
  { id: 4, name: 'Financial Management/CFO' },
  { id: 5, name: 'HR Administration' },
  { id: 6, name: 'Graphic Design' },
  { id: 7, name: 'Consulting' },
  { id: 8, name: 'Recruiting' },
  { id: 9, name: 'Tax Preparation' },
  { id: 10, name: 'Data Entry' },
  { id: 11, name: 'Online Research' },
  { id: 12, name: 'Order Processing' },
  { id: 13, name: 'Project Management' },
  { id: 14, name: 'Transcription' },
  { id: 15, name: 'Virtual/Administrative Assistance' },
  { id: 16, name: 'Customer Service' },
  { id: 17, name: 'Tech Support' },
  { id: 18, name: 'A/B Testing' },
  { id: 19, name: 'Data Analytics' },
  { id: 20, name: 'Data Engineering' },
  { id: 21, name: 'Data Extraction' },
  { id: 22, name: 'Data Mining' },
  { id: 23, name: 'Data Processing' },
  { id: 24, name: 'Data Visualization' },
  { id: 25, name: 'Deep Learning' },
  { id: 26, name: 'Experimentation & Testing' },
  { id: 27, name: 'Knowledge Representation' },
  { id: 28, name: 'Machine Learning' },
  { id: 29, name: 'AI' },
  { id: 30, name: 'Animation' },
  { id: 31, name: 'Actor' },
  { id: 32, name: 'AR/VR Design' },
  { id: 33, name: 'Art Direction' },
  { id: 34, name: 'Audio Editing/Production' },
  { id: 35, name: 'Branding' },
  { id: 36, name: 'Cartoonist' },
  { id: 37, name: 'Fashion Design' },
  { id: 38, name: 'Music production/editing' },
  { id: 39, name: 'Musician' },
  { id: 40, name: 'Photography' },
  { id: 41, name: 'Copywriting' },
  { id: 42, name: 'Social Media Strategy' },
  { id: 43, name: 'Video Editing/Production' },
  { id: 44, name: 'Videographer' },
  { id: 45, name: 'Vocalist' },
  { id: 46, name: 'Voice Talent' },
  { id: 47, name: 'Front-end Development' },
  { id: 48, name: 'Back-end Development' },
  { id: 49, name: 'Full-stack Development' },
  { id: 50, name: 'Web Development' },
  { id: 51, name: 'Mobile Development' },
  { id: 52, name: 'Website Design' },
  { id: 53, name: 'UX/UI Design' },
  { id: 54, name: 'Figma' },
  { id: 55, name: 'Adobe' },
  { id: 56, name: 'Canva' },
  { id: 57, name: 'Github' },
  { id: 58, name: 'AWS' },
  { id: 59, name: 'Firebase' },
  { id: 60, name: 'MongoDB' },
  { id: 61, name: 'Product Management' },
  { id: 62, name: 'Software Engineer' },
  { id: 63, name: 'Cloud Engineering' },
  { id: 64, name: 'Database Administration' },
  { id: 65, name: 'DevOps Engineering' },
  { id: 66, name: 'Information Security' },
  { id: 67, name: 'IT Compliance' },
  { id: 68, name: 'Network Administration' },
  { id: 69, name: 'Network Security' },
  { id: 70, name: 'Solutions Architecture' },
  { id: 71, name: 'Systems Administration' },
  { id: 72, name: 'Systems Engineering' },
  { id: 73, name: 'Scrum Master' },
  { id: 74, name: 'Game Development' },
  { id: 75, name: 'AR/VR Development' },
  { id: 76, name: 'Business & Corporate Law' },
  { id: 77, name: 'General Counsel' },
  { id: 78, name: 'Immigration Law' },
  { id: 79, name: 'Intellectual Property Law' },
  { id: 80, name: 'International Law' },
  { id: 81, name: 'Labor & Employment Law' },
  { id: 82, name: 'Paralegal' },
  { id: 83, name: 'Regulatory Law' },
  { id: 84, name: 'Securities & Finance Law' },
  { id: 85, name: 'Tax Law' },
  { id: 86, name: 'Campaign Management' },
  { id: 87, name: 'Community Management' },
  { id: 88, name: 'Content Strategy' },
  { id: 89, name: 'Digital Marketing' },
  { id: 90, name: 'Email Marketing' },
  { id: 91, name: 'Lead Generation' },
  { id: 92, name: 'Market Research' },
  { id: 93, name: 'Marketing Automation' },
  { id: 94, name: 'Marketing Strategy' },
  { id: 95, name: 'Public Relations' },
  { id: 96, name: 'Sales & Business Development' },
  { id: 97, name: 'Search Engine Marketing' },
  { id: 98, name: 'Search Engine Optimization' },
  { id: 99, name: 'Social Media Marketing' },
  { id: 100, name: 'Telemarketing' },
  { id: 101, name: 'Event Management' },
  { id: 102, name: 'Salesforce' },
  { id: 103, name: 'Mailchimp' },
  { id: 104, name: 'React Native' },
  { id: 105, name: 'React' },
  { id: 106, name: 'Javascript' },
  { id: 107, name: 'Java' },
  { id: 108, name: 'Flutter' },
];

export const followerCountsList = [
  {
    value: '< 5,000',
    label: '< 5,000',
  },
  {
    value: '5,000 - 10,000',
    label: '5,000 - 10,000',
  },
  {
    value: '10,000 - 25,000',
    label: '10,000 - 25,000',
  },
  {
    value: '25,000 - 50,000',
    label: '25,000 - 50,000',
  },
  {
    value: '50,000 - 100,000',
    label: '50,000 - 100,000',
  },
  {
    value: '100,000 - 500,000',
    label: '100,000 - 500,000',
  },
  {
    value: '500k +',
    label: '500k +',
  },
];

export const popularChannelsList = [
  { value: 'TikTok', label: 'TikTok' },
  { value: 'YouTube', label: 'YouTube' },
  { value: 'Twitter', label: 'Twitter' },
  { value: 'Instagram', label: 'Instagram' },
  { value: 'Podcast', label: 'Podcast' },
  { value: 'Linkedin', label: 'Linkedin' },
  { value: 'Clubhouse', label: 'Clubhouse' },
];

// creator fund form
export const expectedEarningsList = [
  { value: '<$500', label: '<$500' },
  { value: '$500 - $1,000', label: '$500 - $1,000' },
  { value: '$1,000 - $2,000', label: '$1,000 - $2,000' },
  { value: '$2,000 - $4,000', label: '$2,000 - $4,000' },
  { value: '$4,000 - $6,000', label: '$4,000 - $6,000' },
  { value: '$6,000 - $8,000', label: '$6,000 - $8,000' },
  { value: '$8k +', label: '$8k +' },
];

export const MY_JOBS_FILTER = 'my-jobs';
export const MY_APPLICATIONS_FILTER = 'my-applications';
export const AVAILABLE_FILTER = 'available-jobs';

export const topics = [
  { value: 'NFTs', label: 'NFTs' },
  { value: 'Metaverse', label: 'Metaverse' },
  { value: 'Cryptocurrencies', label: 'Cryptocurrencies' },
  { value: 'Blockchain', label: 'Blockchain' },
  { value: 'Web3', label: 'Web3' },
  { value: 'Venture Capital', label: 'Venture Capital' },
  { value: 'Angel Investing', label: 'Angel Investing' },
  { value: 'Stocks/Trading', label: 'Stocks/Trading' },
  { value: 'UI/UX Design', label: 'UI/UX Design' },
  { value: 'Marketing/Advertising', label: 'Marketing/Advertising' },
  { value: 'Coding/Programming', label: 'Coding/Programming' },
  { value: 'Real Estate', label: 'Real Estate' },
  { value: 'Personal Development', label: 'Personal Development' },
  { value: 'Health/Wellness', label: 'Health/Wellness' },
  { value: 'SaaS', label: 'SaaS' },
  { value: 'Community', label: 'Community' },
  { value: 'Fintech', label: 'Fintech' },
];

// internship form

export const positionInterestedInList = [
  { value: 'Social Media Manager', label: 'Social Media Manager' },
  { value: 'Graphic Designer', label: 'Graphic Designer' },
  { value: 'Video Editor', label: 'Video Editor' },
  { value: 'Content Creator', label: 'Content Creator' },
  { value: 'UX/UI Designer', label: 'UX/UI Designer' },
  { value: 'Community Manager', label: 'Community Manager' },
  { value: 'Software Engineer', label: 'Software Engineer' },
];

export const whenCanStartList = [
  { value: 'Immediately', label: 'Immediately' },
  { value: 'One week', label: 'One week' },
  { value: 'Two weeks', label: 'Two weeks' },
  { value: 'One month', label: 'One month' },
  { value: 'More than one month', label: 'More than one month' },
];

export const expectedEarningList = [
  { value: 'Paid', label: 'Paid' },
  { value: 'Unpaid', label: 'Unpaid' },
];

export const heardEntreInternshipList = [
  { value: 'Chegg/CareerMatch', label: 'Chegg/CareerMatch' },
  { value: 'Handshake', label: 'Handshake' },
  { value: 'InternshipFinder', label: 'InternshipFinder' },
  { value: 'LinkedIn', label: 'LinkedIn' },
  { value: 'Indeed', label: 'Indeed' },
  { value: 'Twitter', label: 'Twitter' },
  { value: 'Entre', label: 'Entre' },
  { value: 'Referred by a friend', label: 'Referred by a friend' },
];
