import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { getStore } from '../../store';
import firebase from '../../src/firebase';

const BASE_URL = process.env.NEW_API_URL;

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 120000,
  params: {},
  headers: {
    'Content-Type': 'application/json',
  },
});

const axiosOther = axios.create({
  timeout: 120000,
  params: {},
  headers: {
    'Content-Type': 'application/json',
  },
});

const tokenExpired = () => {
  const authHeader = axiosInstance.defaults.headers.common.Authorization;
  if (authHeader) {
    const token = authHeader.replace('Bearer ', '');
    const [, body] = token.split('.');
    const decodedData = window.atob(body);
    const data = JSON.parse(decodedData);
    if (Date.now() >= data.exp * 1000) {
      return true;
    }
  }
  return false;
};

const pendingCancelSource = {};
let pendingRequests = [];

const processResponse = async (fn, args, id = null) => {
  try {
    if (tokenExpired()) {
      const { currentUser } = firebase.auth();
      if (currentUser) {
        const idToken = await currentUser.getIdToken();
        getStore().dispatch({
          type: 'SET_IDTOKEN_SUCCESS',
          payload: idToken || undefined,
        });
        axiosInstance.defaults.headers.common.Authorization = `Bearer ${idToken}`;
      }
    }
    const response = await fn(...args);
    if (id) { // clean up from pending
      pendingRequests = pendingRequests.filter((r) => r.id === id);
    }
    return response;
  } catch (err) {
    if (err.response) {
      return {
        data: err.response.data,
      };
    }
    return { data: err.message };
  }
};

export default {
  setToken: (token) => {
    if (token) {
      axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
    } else {
      delete axiosInstance.defaults.headers.common.Authorization;
    }
  },
  axiosOther,
  async get(resource, cancelId = null, cancelable = false) {
    const options = {};
    let id;
    if (pendingCancelSource[cancelId]) {
      pendingCancelSource[cancelId].cancel([]);
    }
    if (cancelable) {
      const { CancelToken } = axios;
      const cancelToken = CancelToken.source();
      if (cancelId) {
        pendingCancelSource[cancelId] = cancelToken;
      }
      id = uuidv4();
      pendingRequests.push({ cancelToken, id });
      options.cancelToken = cancelToken.token;
    }
    return processResponse(axiosInstance.get, [`${BASE_URL}/${resource}`, options], id);
  },
  cancelAll() {
    pendingRequests.forEach((req) => req.cancelToken.cancel());
    pendingRequests.splice(0, pendingRequests.length);
  },
  async post(resource, body) {
    return processResponse(axiosInstance.post, [`${BASE_URL}/${resource}`, body]);
  },
  async patch(resource, body) {
    return processResponse(axiosInstance.patch, [`${BASE_URL}/${resource}`, body]);
  },
  async put(resource, body) {
    return processResponse(axiosInstance.put, [`${BASE_URL}/${resource}`, body]);
  },
  async delete(resource, body) {
    return processResponse(axiosInstance.delete, [`${BASE_URL}/${resource}`, body]);
  },
  async upload(resource, body) {
    return processResponse(axiosInstance.post, [`${BASE_URL}/${resource}`, body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }]);
  },
};
