/* eslint-disable no-underscore-dangle */
import { handleActions } from 'redux-actions';
import {
  loadJobPosts,
  resetJobPosts,
  loadJobPostsComplete,
  loadJobPost,
  loadJobPostComplete,
  setJobFilter,
  deleteJobPostComplete,
  loadJobPostNotFound,
} from '../actions/jobActions';
import { loadCompanyJobs, loadCompanyJobsComplete } from '../actions';
import { AVAILABLE_FILTER } from '../jobConstant';

const INITIAL_STATE = {
  loadingPost: false,
  loadingPosts: false,
  hasMorePosts: false,
  post: null,
  currentJobFeedFilter: AVAILABLE_FILTER,
  posts: [],
  loadJobPostNotFound: false,
};

const reducer = handleActions(
  {
    [setJobFilter]: (state, { payload }) => ({ ...state, currentJobFeedFilter: payload }),
    [loadJobPosts]: (state) => ({ ...state, loadingPosts: true }),
    [resetJobPosts]: (state) => ({ ...state, posts: INITIAL_STATE.posts }),
    [loadJobPostsComplete]: (state, { payload }) => {
      if (payload && Array.isArray(payload) && payload.length > 0) {
        const currentIds = state.posts.map((e) => e.id || e._id);
        const filterPayload = payload.filter((e) => !currentIds.includes(e.id || e._id));
        const posts = [...state.posts, ...filterPayload];
        return {
          ...state,
          posts,
          hasMorePosts: payload.length > 0,
          loadingPosts: false,
        };
      }
      if (payload && Array.isArray(payload) && payload.length === 0) {
        return {
          ...state,
          hasMorePosts: false,
          loadingPosts: false,
        };
      }
      return state;
    },
    [loadJobPost]: (state) => ({ ...state, loadingPost: true }),
    [loadJobPostComplete]: (state, { payload }) => ({
      ...state,
      post: payload,
      loadingPost: false,
    }),
    [loadCompanyJobs]: (state) => ({ ...state, loadingPosts: true }),
    [loadCompanyJobsComplete]: (state, { payload }) => ({
      ...state,
      posts: payload,
      loadingPosts: false,
    }),
    [deleteJobPostComplete]: (state, { payload }) => ({
      ...state,
      posts: state.posts.filter((post) => post.id !== payload),
    }),
    [loadJobPostNotFound]: (state) => ({
      ...state,
      loadJobPostNotFound: true,
      loadingPost: false,
    }),
  },
  INITIAL_STATE,
);

export default reducer;
